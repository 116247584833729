
import React, { Component } from "react";

import Loader from "@uicomponents/Loader";
import AsideView from "@uicomponents/AsideView";

import BrandForm from "./BrandForm";

export default class UpdateBrandPage extends Component {
    
    componentDidMount() {
        const { onChange, ownProps } = this.props;
        onChange("formBrand", ownProps.brand);
    }

    render() {
        const { t, formBrand, isFetchingBrand, updateBrand } = this.props;

        if (!formBrand || isFetchingBrand) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("brand.update")}</h1>
                <BrandForm
                    {...this.props}
                    key={formBrand.id}
                    onSubmit={(brand) => updateBrand(brand)}
                />
            </AsideView>
        );
    }
}
    