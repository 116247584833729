
export const invoiceConstants = {
    INVOICE_CHANGE_VALUE: "INVOICE_CHANGE_VALUE",

    GET_INVOICE_STARTED: "GET_INVOICE_STARTED",
    GET_INVOICE_SUCCESS: "GET_INVOICE_SUCCESS",
    GET_INVOICE_FAILURE: "GET_INVOICE_FAILURE",

    GET_INVOICES_STARTED: "GET_INVOICES_STARTED",
    GET_INVOICES_SUCCESS: "GET_INVOICES_SUCCESS",
    GET_INVOICES_FAILURE: "GET_INVOICES_FAILURE",

    SEARCH_INVOICES_STARTED: "SEARCH_INVOICES_STARTED",
    SEARCH_INVOICES_SUCCESS: "SEARCH_INVOICES_SUCCESS",
    SEARCH_INVOICES_FAILURE: "SEARCH_INVOICES_FAILURE",

    CREATING_INVOICE_STARTED: "CREATING_INVOICE_STARTED",
    CREATING_INVOICE_SUCCESS: "CREATING_INVOICE_SUCCESS",
    CREATING_INVOICE_FAILURE: "CREATING_INVOICE_FAILURE",

    UPDATING_INVOICE_STARTED: "UPDATING_INVOICE_STARTED",
    UPDATING_INVOICE_SUCCESS: "UPDATING_INVOICE_SUCCESS",
    UPDATING_INVOICE_FAILURE: "UPDATING_INVOICE_FAILURE",

    DELETE_INVOICE_STARTED: "DELETE_INVOICE_STARTED",
    DELETE_INVOICE_SUCCESS: "DELETE_INVOICE_SUCCESS",
    DELETE_INVOICE_FAILURE: "DELETE_INVOICE_FAILURE",
};
