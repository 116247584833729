import { notificationConstants } from "../constants";

const initialState = {
    notificationsIsFetching: false,
    notificationsTotalResults: 0,
    notificationsUnread: 0,
    notifications: [],

    notificationIsFetching: false,
    notification: null,
};

export default function notifications(state = initialState, action) {
    switch (action.type) {
        case notificationConstants.GET_NOTIFICATIONS_STARTED:
            return { ...state, notificationsIsFetching: true };
        case notificationConstants.GET_NOTIFICATIONS_FAILURE:
            return { ...state, notificationsIsFetching: false, message: action.message || "" };
        case notificationConstants.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notificationsIsFetching: false,
                notifications: action.notifications,
                notificationsTotalResults: action.totalResults || 0,
                notificationsUnread: action.notificationsUnread || 0,
            };
        case notificationConstants.READ_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notificationsUnread: state.notificationsUnread - action.notifications.length,
                notifications: [...state.notifications].map(
                    (obj) => action.notifications.find((o) => o.id === obj.id) || obj
                ),
            };
        default:
            return state;
    }
}
