import React, { Component } from "react";
import Loader from "../ui/components/Loader";

export default class HeaderAccount extends Component {

    render() {
        const { user } = this.props;
        
        if (user) {
            return (
                <div className="info">
                    <div className="name">{`${user.firstName} ${user.lastName}`}</div>
                    <div className="email">{user.email}</div>
                </div>
            );
        }

        return <Loader />;
    }
}
