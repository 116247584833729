
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import BrandsView from "@components/brand/BrandsView";

import * as brandActions from "@actions/brandActions";
import * as navigationActions from "@actions/navigationActions";

class BrandsContainer extends PureComponent {
    render() {
        const {  getBrands, searchBrands, deleteBrand, ...props } = this.props;
        return (
            <BrandsView
                {...props}
                getBrands={(from = 0, to = 20) => getBrands( from, to)}
                searchBrands={(searchParameters, from, to) =>
                    searchBrands( searchParameters, from, to)
                }

                deleteBrand={(brand) => deleteBrand( brand)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        ...state.brand,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBrands: ( from, to) => dispatch(brandActions.getBrands( from, to)),
        searchBrands: ( searchParameters, from, to) =>
            dispatch(brandActions.searchBrands( searchParameters, from, to)),
        deleteBrand: ( brand) => dispatch(brandActions.deleteBrand( brand)),
        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(BrandsContainer));
    
    