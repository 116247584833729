
export const driverConstants = {
    DRIVER_CHANGE_VALUE: "DRIVER_CHANGE_VALUE",

    GET_DRIVER_STARTED: "GET_DRIVER_STARTED",
    GET_DRIVER_SUCCESS: "GET_DRIVER_SUCCESS",
    GET_DRIVER_FAILURE: "GET_DRIVER_FAILURE",

    GET_DRIVERS_STARTED: "GET_DRIVERS_STARTED",
    GET_DRIVERS_SUCCESS: "GET_DRIVERS_SUCCESS",
    GET_DRIVERS_FAILURE: "GET_DRIVERS_FAILURE",

    SEARCH_DRIVERS_STARTED: "SEARCH_DRIVERS_STARTED",
    SEARCH_DRIVERS_SUCCESS: "SEARCH_DRIVERS_SUCCESS",
    SEARCH_DRIVERS_FAILURE: "SEARCH_DRIVERS_FAILURE",

    CREATING_DRIVER_STARTED: "CREATING_DRIVER_STARTED",
    CREATING_DRIVER_SUCCESS: "CREATING_DRIVER_SUCCESS",
    CREATING_DRIVER_FAILURE: "CREATING_DRIVER_FAILURE",

    UPDATING_DRIVER_STARTED: "UPDATING_DRIVER_STARTED",
    UPDATING_DRIVER_SUCCESS: "UPDATING_DRIVER_SUCCESS",
    UPDATING_DRIVER_FAILURE: "UPDATING_DRIVER_FAILURE",

    DELETE_DRIVER_STARTED: "DELETE_DRIVER_STARTED",
    DELETE_DRIVER_SUCCESS: "DELETE_DRIVER_SUCCESS",
    DELETE_DRIVER_FAILURE: "DELETE_DRIVER_FAILURE",
};
