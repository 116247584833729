
export const vehicleConstants = {
    VEHICLE_CHANGE_VALUE: "VEHICLE_CHANGE_VALUE",

    GET_VEHICLE_STARTED: "GET_VEHICLE_STARTED",
    GET_VEHICLE_SUCCESS: "GET_VEHICLE_SUCCESS",
    GET_VEHICLE_FAILURE: "GET_VEHICLE_FAILURE",

    GET_VEHICLES_STARTED: "GET_VEHICLES_STARTED",
    GET_VEHICLES_SUCCESS: "GET_VEHICLES_SUCCESS",
    GET_VEHICLES_FAILURE: "GET_VEHICLES_FAILURE",

    SEARCH_VEHICLES_STARTED: "SEARCH_VEHICLES_STARTED",
    SEARCH_VEHICLES_SUCCESS: "SEARCH_VEHICLES_SUCCESS",
    SEARCH_VEHICLES_FAILURE: "SEARCH_VEHICLES_FAILURE",

    CREATING_VEHICLE_STARTED: "CREATING_VEHICLE_STARTED",
    CREATING_VEHICLE_SUCCESS: "CREATING_VEHICLE_SUCCESS",
    CREATING_VEHICLE_FAILURE: "CREATING_VEHICLE_FAILURE",

    UPDATING_VEHICLE_STARTED: "UPDATING_VEHICLE_STARTED",
    UPDATING_VEHICLE_SUCCESS: "UPDATING_VEHICLE_SUCCESS",
    UPDATING_VEHICLE_FAILURE: "UPDATING_VEHICLE_FAILURE",

    DELETE_VEHICLE_STARTED: "DELETE_VEHICLE_STARTED",
    DELETE_VEHICLE_SUCCESS: "DELETE_VEHICLE_SUCCESS",
    DELETE_VEHICLE_FAILURE: "DELETE_VEHICLE_FAILURE",
};
