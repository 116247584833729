
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import BrandView from "@components/brand/BrandView";

import * as brandActions from "@actions/brandActions";
import * as navigationActions from "@actions/navigationActions";

class BrandContainer extends PureComponent {
    componentDidMount() {
        const { ownProps, changeValue, getBrand } = this.props;
        if (ownProps.brand) {
            changeValue("brand", ownProps.brand);
        } else if (ownProps.brandId) {
            getBrand(ownProps.brandId);
        }
    }

    render() {
        const { changeValue, getBrand, deleteBrand, ...props } = this.props;
        return (
            <BrandView
                {...props}
                onChange={changeValue}
                getBrand={(id = "") => getBrand(id)}
                deleteBrand={(brand) => deleteBrand(brand)}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.auth.user,
        ...state.brand,
        
        ownProps: ownProps,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (name, value) => dispatch(brandActions.changeValue(name, value)),

        getBrand: (id) => dispatch(brandActions.getBrand(id)),
        deleteBrand: (brand) => dispatch(brandActions.deleteBrand(brand)),

        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translation")(BrandContainer));
    
    