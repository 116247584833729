import React, { Component } from "react";

import Loader from "../ui/components/Loader";

import GeneralSettings from "./GeneralSettings";
import NotificationsSettings from "./NotificationsSettings";
import PrivacySettings from "./PrivacySettings";
import SecuritySettings from "./SecuritySettings";

export default class SettingsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: "general",
        };
    }

    render() {
        const { t, settings, settingsIsFetching, languagesIsFetching } = this.props;
        const { tab } = this.state;

        if (!settings || settingsIsFetching || languagesIsFetching) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <div className="settings">
                <div className="container">
                    <div className="wrapper top maximal">
                        <div className="__box">
                            <div className="tabs left">
                                <ul>
                                    <li
                                        className={tab === "general" ? "tab active" : "tab"}
                                        onClick={(e) => this.setState({ tab: "general" })}
                                    >
                                        {t("settings.general.header")}
                                    </li>
                                    <li
                                        className={tab === "security" ? "tab active" : "tab"}
                                        onClick={(e) => this.setState({ tab: "security" })}
                                    >
                                        {t("settings.security.header")}
                                    </li>
                                    <li
                                        className={tab === "notification" ? "tab active" : "tab"}
                                        onClick={(e) => this.setState({ tab: "notification" })}
                                    >
                                        {t("settings.notifications.header")}
                                    </li>
                                </ul>
                            </div>
                            <section className={tab === "general" ? "activity active" : "activity"}>
                                <div className="container big-box">
                                    <h1>{t("settings.general.header")}</h1>
                                    <GeneralSettings {...this.props} />
                                </div>
                            </section>
                            <section className={tab === "security" ? "activity active" : "activity"}>
                                <div className="container big-box">
                                    <h1>{t("settings.security.header")}</h1>
                                    <SecuritySettings {...this.props} />
                                </div>
                            </section>
                            <section className={tab === "notification" ? "activity active" : "activity"}>
                                <div className="container big-box">
                                    <h1>{t("settings.notifications.header")}</h1>
                                    <NotificationsSettings {...this.props} />
                                </div>
                            </section>
                            <section className={tab === "privacy" ? "activity active" : "activity"}>
                                <div className="container big-box">
                                    <h1>{t("settings.privacy.header")}</h1>
                                    <PrivacySettings {...this.props} />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
