import React, { Component } from "react";

export default class Loader extends Component {
    render() {
        const { text, style } = this.props;
        if (text) {
            return (
                <div style={style}>
                    <div className="lds-ripple"><div></div><div></div></div>
                    <h3 className="center">{text}</h3>
                </div>
            );
        }
        return <div style={style} className="lds-ripple"><div></div><div></div></div>;
    }
}
