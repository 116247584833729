
export const insuranceConstants = {
    INSURANCE_CHANGE_VALUE: "INSURANCE_CHANGE_VALUE",

    GET_INSURANCE_STARTED: "GET_INSURANCE_STARTED",
    GET_INSURANCE_SUCCESS: "GET_INSURANCE_SUCCESS",
    GET_INSURANCE_FAILURE: "GET_INSURANCE_FAILURE",

    GET_INSURANCES_STARTED: "GET_INSURANCES_STARTED",
    GET_INSURANCES_SUCCESS: "GET_INSURANCES_SUCCESS",
    GET_INSURANCES_FAILURE: "GET_INSURANCES_FAILURE",

    SEARCH_INSURANCES_STARTED: "SEARCH_INSURANCES_STARTED",
    SEARCH_INSURANCES_SUCCESS: "SEARCH_INSURANCES_SUCCESS",
    SEARCH_INSURANCES_FAILURE: "SEARCH_INSURANCES_FAILURE",

    CREATING_INSURANCE_STARTED: "CREATING_INSURANCE_STARTED",
    CREATING_INSURANCE_SUCCESS: "CREATING_INSURANCE_SUCCESS",
    CREATING_INSURANCE_FAILURE: "CREATING_INSURANCE_FAILURE",

    UPDATING_INSURANCE_STARTED: "UPDATING_INSURANCE_STARTED",
    UPDATING_INSURANCE_SUCCESS: "UPDATING_INSURANCE_SUCCESS",
    UPDATING_INSURANCE_FAILURE: "UPDATING_INSURANCE_FAILURE",

    DELETE_INSURANCE_STARTED: "DELETE_INSURANCE_STARTED",
    DELETE_INSURANCE_SUCCESS: "DELETE_INSURANCE_SUCCESS",
    DELETE_INSURANCE_FAILURE: "DELETE_INSURANCE_FAILURE",
};
