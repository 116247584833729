import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import CreateUserPage from '@components/user/crud/CreateUserPage.js';

import * as userActions from '@actions/userActions';
import * as authActions from '@actions/authActions';
import * as navigationActions from '@actions/navigationActions';

class CreateUserContainer extends Component {
    render() {
        const { changeValue, createUser, inviteUser, popStack, ...props } = this.props;

        return (
            <CreateUserPage
                {...props}
                onChange={changeValue}
                createUser={(user) =>
                    createUser(user).then((response) => {
                        popStack();
                    })
                }
                inviteUser={(user) =>
                    inviteUser(user).then((response) => {
                        popStack();
                    })
                }
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.user,

        ...state.auth,
        ...state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createUser: (user) => dispatch(userActions.createUser(user)),
        inviteUser: (user) => dispatch(userActions.inviteUser(user)),
        changeValue: (name, value) => dispatch(userActions.changeValue(name, value)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(CreateUserContainer));
