import React, { Component } from "react";

import Alert from "./Alert";

export default class ComponentStack extends Component {
    render() {
        const { alerts } = this.props;

        return (
            <div className="alert-stack">
                {alerts.map((alert) => (
                    <Alert
                        alert={alert}
                        className={`${alert.animatingOut ? " animateOut" : " animateIn"}`}
                        key={`alert-stack-${alert.id}`}
                    />
                ))}
            </div>
        );
    }
}
