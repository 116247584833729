import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";

import AuthenticationContainer from "./AuthenticationContainer";

export default class ForgotResetPage extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onReset } = this.props;

        if (this.validator.allValid()) {
            onReset();
        } else {
            this.validator.showMessages();
        }
    }

    render() {
        const { t, onChange, password, repeatPassword, error } = this.props;

        return (
            <AuthenticationContainer {...this.props} header={t('reset.header')}>
                <form method="post" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <input
                                type="password"
                                autoComplete="off"
                                value={password}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    onChange('password', value, event);
                                }}
                            />
                            <label>{t('form.label.password')}</label>
                        </div>
                        {this.validator.message(t('form.label.password'), password, 'required|min:6')}
                    </div>
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <input
                                type="password"
                                autoComplete="off"
                                value={repeatPassword}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    onChange('repeatPassword', value, event);
                                }}
                            />
                            <label>{t('form.label.passwordRepeat')}</label>
                        </div>
                        {this.validator.message(t('form.label.passwordRepeat'), repeatPassword, 'required')}
                    </div>
                    <div className="input-group right">
                        <div className="msg bottom">{error}</div>
                        <input type="submit" value={t("form.reset")} onClick={(e) => e.stopPropagation()}/>
                    </div>
                </form>
            </AuthenticationContainer>
        );
    }
}
