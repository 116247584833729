
import { driverConstants } from "@constants/driver.constants";

const initialState = {
    isFetchingDriver: false,
    formDriver: null,
    driver: null,

    isFetchingDrivers: false,
    isFetchingDriversFrom: 0,
    driversTotalResults: 0,
    driversLoaded: 0,
    drivers: [],
};

export default function driver(state = initialState, action) {
    switch (action.type) {
        case driverConstants.DRIVER_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case driverConstants.GET_DRIVER_STARTED:
            return { ...state, isFetchingDriver: true };
        case driverConstants.GET_DRIVER_FAILURE:
            return { ...state, isFetchingDriver: false, driver: null, error: action.message || "" };
        case driverConstants.GET_DRIVER_SUCCESS:
            return {
                ...state,
                isFetchingDriver: false,
                driver: action.driver,
            };
            
        case driverConstants.GET_DRIVERS_STARTED:
            return { ...state, isFetchingDrivers: true,  isFetchingDriversFrom: action.from || 0 };
        case driverConstants.GET_DRIVERS_FAILURE:
            return { ...state, isFetchingDrivers: false, drivers: [], error: action.message || "" };
        case driverConstants.GET_DRIVERS_SUCCESS:
            return {
                ...state,
                isFetchingDrivers: false,
                drivers: action.from > 0 ? [...state.drivers, ...action.drivers] : action.drivers,
                driversLoaded:
                    action.from > 0
                        ? state.drivers.length + action.drivers.length
                        : action.drivers.length,
                driversTotalResults: action.totalResults || 0,
            };

        case driverConstants.SEARCH_DRIVERS_STARTED:
            return { ...state, isFetchingDrivers: true, isFetchingDriversFrom: action.from || 0  };
        case driverConstants.SEARCH_DRIVERS_FAILURE:
            return { ...state, isFetchingDrivers: false, drivers: [], error: action.message || "" };
        case driverConstants.SEARCH_DRIVERS_SUCCESS:
            return {
                ...state,
                isFetchingDrivers: false,
                drivers: action.from > 0 ? [...state.drivers, ...action.drivers] : action.drivers,
                driversLoaded:
                    action.from > 0
                        ? state.drivers.length + action.drivers.length
                        : action.drivers.length,
                driversTotalResults: action.totalResults || 0,
            };

        case driverConstants.CREATING_DRIVER_STARTED:
            return { ...state, isFetchingDriver: true };
        case driverConstants.CREATING_DRIVER_FAILURE:
            return { ...state, isFetchingDriver: false, driver: null, error: action.message || "" };
        case driverConstants.CREATING_DRIVER_SUCCESS:
            return {
                ...state,
                isFetchingDriver: false,
                driver: action.driver,
                drivers: [action.driver, ...state.drivers],
            };

        case driverConstants.UPDATING_DRIVER_STARTED:
            return { ...state, isFetchingDriver: true };
        case driverConstants.UPDATING_DRIVER_FAILURE:
            return { ...state, isFetchingDriver: false, driver: null, error: action.message || "" };
        case driverConstants.UPDATING_DRIVER_SUCCESS:
            return {
                ...state,
                isFetchingDriver: false,
                driver: action.driver,
                drivers: [...state.drivers].map((driver) => {
                    if (action.driver.id === driver.id) {
                        return action.driver;
                    }
                    return driver;
                }),
            };

        case driverConstants.DELETE_DRIVER_STARTED:
            return { ...state, isFetchingDriver: true };
        case driverConstants.DELETE_DRIVER_FAILURE:
            return { ...state, isFetchingDriver: false, driver: null, message: action.message || "" };
        case driverConstants.DELETE_DRIVER_SUCCESS:
            return {
                ...state,
                isFetchingDriver: false,
                driver: action.driver,
                drivers: [...state.drivers].filter((driver) => action.driver.id !== driver.id),
            };
        default:
            return state;
    }
}    
    