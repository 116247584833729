import React, { Component } from "react";
import { Link } from "react-router-dom";

import AuthenticationContainer from "./AuthenticationContainer";
import SimpleReactValidator from "simple-react-validator";

export default class ForgotPage extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.props.onForgot();
        } else {
            this.validator.showMessages();
        }
    }

    render() {
        const { t, onChange, email, error } = this.props;

        return (
            <AuthenticationContainer
                header={t("forgot.header")}
                link={<Link to={"/login"}>{t("forgot.backToLogin")}</Link>}
                {...this.props}
            >
                <form method="post" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <input
                                type="text"
                                value={this.email}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    onChange("email", value, event);
                                }}
                            />
                            <label>{t("form.label.email")}</label>
                        </div>
                        {this.validator.message(t("form.label.email"), email, "required|email")}
                    </div>
                    <div className="input-group right">
                        <div className="msg bottom">{error}</div>
                        <input
                            type="submit"
                            value={t("forgot.header")}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </div>
                </form>
            </AuthenticationContainer>
        );
    }
}
