
import { itemConstants } from "@constants/item.constants";

const initialState = {
    isFetchingItem: false,
    formItem: null,
    item: null,

    isFetchingItems: false,
    isFetchingItemsFrom: 0,
    itemsTotalResults: 0,
    itemsLoaded: 0,
    items: [],
};

export default function item(state = initialState, action) {
    switch (action.type) {
        case itemConstants.ITEM_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case itemConstants.GET_ITEM_STARTED:
            return { ...state, isFetchingItem: true };
        case itemConstants.GET_ITEM_FAILURE:
            return { ...state, isFetchingItem: false, item: null, error: action.message || "" };
        case itemConstants.GET_ITEM_SUCCESS:
            return {
                ...state,
                isFetchingItem: false,
                item: action.item,
            };
            
        case itemConstants.GET_ITEMS_STARTED:
            return { ...state, isFetchingItems: true,  isFetchingItemsFrom: action.from || 0 };
        case itemConstants.GET_ITEMS_FAILURE:
            return { ...state, isFetchingItems: false, items: [], error: action.message || "" };
        case itemConstants.GET_ITEMS_SUCCESS:
            return {
                ...state,
                isFetchingItems: false,
                items: action.from > 0 ? [...state.items, ...action.items] : action.items,
                itemsLoaded:
                    action.from > 0
                        ? state.items.length + action.items.length
                        : action.items.length,
                itemsTotalResults: action.totalResults || 0,
            };

        case itemConstants.SEARCH_ITEMS_STARTED:
            return { ...state, isFetchingItems: true, isFetchingItemsFrom: action.from || 0  };
        case itemConstants.SEARCH_ITEMS_FAILURE:
            return { ...state, isFetchingItems: false, items: [], error: action.message || "" };
        case itemConstants.SEARCH_ITEMS_SUCCESS:
            return {
                ...state,
                isFetchingItems: false,
                items: action.from > 0 ? [...state.items, ...action.items] : action.items,
                itemsLoaded:
                    action.from > 0
                        ? state.items.length + action.items.length
                        : action.items.length,
                itemsTotalResults: action.totalResults || 0,
            };

        case itemConstants.CREATING_ITEM_STARTED:
            return { ...state, isFetchingItem: true };
        case itemConstants.CREATING_ITEM_FAILURE:
            return { ...state, isFetchingItem: false, item: null, error: action.message || "" };
        case itemConstants.CREATING_ITEM_SUCCESS:
            return {
                ...state,
                isFetchingItem: false,
                item: action.item,
                items: [action.item, ...state.items],
            };

        case itemConstants.UPDATING_ITEM_STARTED:
            return { ...state, isFetchingItem: true };
        case itemConstants.UPDATING_ITEM_FAILURE:
            return { ...state, isFetchingItem: false, item: null, error: action.message || "" };
        case itemConstants.UPDATING_ITEM_SUCCESS:
            return {
                ...state,
                isFetchingItem: false,
                item: action.item,
                items: [...state.items].map((item) => {
                    if (action.item.id === item.id) {
                        return action.item;
                    }
                    return item;
                }),
            };

        case itemConstants.DELETE_ITEM_STARTED:
            return { ...state, isFetchingItem: true };
        case itemConstants.DELETE_ITEM_FAILURE:
            return { ...state, isFetchingItem: false, item: null, message: action.message || "" };
        case itemConstants.DELETE_ITEM_SUCCESS:
            return {
                ...state,
                isFetchingItem: false,
                item: action.item,
                items: [...state.items].filter((item) => action.item.id !== item.id),
            };
        default:
            return state;
    }
}    
    