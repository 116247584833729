import React, { Component } from 'react';
import Select from 'react-select';
import i18n from '../../i18n';

import { activateInputs } from '../../utils/formUtils';

import Loader from '../ui/components/Loader';

export default class GeneralSettings extends Component {
    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { updateUser, settings } = this.props;

        i18n.changeLanguage(settings.user.language.code.toLowerCase());
        updateUser({ ...settings.user });
    };

    render() {
        const { onChange, t, settings, languages, generalSettingsIsFetching } = this.props;

        if (generalSettingsIsFetching) {
            return <Loader />;
        }

        return (
            <form onSubmit={(e) => this.handleSubmit(e)}>
                <div className="input-group">
                    <div className="input-group no-margin-top half">
                        <input
                            type="text"
                            value={settings.user.firstName}
                            onChange={(e) => {
                                e.preventDefault();
                                const newSettings = { ...settings };
                                const newUser = { ...newSettings.user };
                                newUser.firstName = e.target.value;
                                newSettings.user = newUser;

                                onChange('settings', newSettings);
                            }}
                        />
                        <label>{t('form.label.firstName')}</label>
                    </div>
                    <div className="input-group no-margin-top half">
                        <input
                            type="text"
                            value={settings.user.lastName}
                            onChange={(e) => {
                                e.preventDefault();
                                const newSettings = { ...settings };
                                const newUser = { ...newSettings.user };
                                newUser.lastName = e.target.value;
                                newSettings.user = newUser;

                                onChange('settings', newSettings);
                            }}
                        />
                        <label>{t('form.label.lastName')}</label>
                    </div>
                </div>
                <div className="input-group more">
                    <input
                        type="text"
                        value={settings.user.username}
                        onChange={(e) => {
                            e.preventDefault();
                            const newSettings = { ...settings };
                            const newUser = { ...newSettings.user };
                            newUser.username = e.target.value;
                            newSettings.user = newUser;

                            onChange('settings', newSettings);
                        }}
                    />
                    <label>{t('form.label.username')}</label>
                </div>
                <div className="input-group">
                    <input
                        type="text"
                        value={settings.user.email}
                        onChange={(e) => {
                            e.preventDefault();
                            const newSettings = { ...settings };
                            const newUser = { ...newSettings.user };
                            newUser.email = e.target.value;
                            newSettings.user = newUser;

                            onChange('settings', newSettings);
                        }}
                    />
                    <label>{t('form.label.email')}</label>
                </div>
                <div className="input-group right more">
                    <input type="submit" value={t('form.update')} />
                </div>
            </form>
        );
    }
}
