
export const locationConstants = {
    LOCATION_CHANGE_VALUE: "LOCATION_CHANGE_VALUE",

    GET_LOCATION_STARTED: "GET_LOCATION_STARTED",
    GET_LOCATION_SUCCESS: "GET_LOCATION_SUCCESS",
    GET_LOCATION_FAILURE: "GET_LOCATION_FAILURE",

    GET_LOCATIONS_STARTED: "GET_LOCATIONS_STARTED",
    GET_LOCATIONS_SUCCESS: "GET_LOCATIONS_SUCCESS",
    GET_LOCATIONS_FAILURE: "GET_LOCATIONS_FAILURE",

    SEARCH_LOCATIONS_STARTED: "SEARCH_LOCATIONS_STARTED",
    SEARCH_LOCATIONS_SUCCESS: "SEARCH_LOCATIONS_SUCCESS",
    SEARCH_LOCATIONS_FAILURE: "SEARCH_LOCATIONS_FAILURE",

    CREATING_LOCATION_STARTED: "CREATING_LOCATION_STARTED",
    CREATING_LOCATION_SUCCESS: "CREATING_LOCATION_SUCCESS",
    CREATING_LOCATION_FAILURE: "CREATING_LOCATION_FAILURE",

    UPDATING_LOCATION_STARTED: "UPDATING_LOCATION_STARTED",
    UPDATING_LOCATION_SUCCESS: "UPDATING_LOCATION_SUCCESS",
    UPDATING_LOCATION_FAILURE: "UPDATING_LOCATION_FAILURE",

    DELETE_LOCATION_STARTED: "DELETE_LOCATION_STARTED",
    DELETE_LOCATION_SUCCESS: "DELETE_LOCATION_SUCCESS",
    DELETE_LOCATION_FAILURE: "DELETE_LOCATION_FAILURE",
};
