import React from 'react';
import { Redirect } from 'react-router-dom';
import { i18n, store } from '@/index';

import Alert from '@models/Alert';
import * as navigationActions from '@actions/navigationActions';

export function handleError(error) {
    const response = error.response || error;
    if (!error && !response) return null;

    if (response.request && (response.request.status === 401 || response.request.status === 403)) {
        // Go to logout if an authentication error occured when not logged in
        if (response.data.code === 403 && !window.location.href.includes('logout')) {
            if (store.getState().auth.isAuthenticated) {
                window.location.href = '/logout';
            }
        } else {
            store.dispatch(
                navigationActions.createAlert(
                    new Alert(
                        i18n.t('notification.noAuthorization.error.title'),
                        i18n.t('notification.noAuthorization.error.description'),
                        'error'
                    )
                )
            );
        }
        return response.data;
    }

    if (response.request && response.request.status === 409) {
        store.dispatch(
            navigationActions.createAlert(
                new Alert(i18n.t('notification.conflict.error.title'), i18n.t('notification.conflict.error.description'), 'error')
            )
        );
    }
    if (response.request && response.request.status === 500) {
        store.dispatch(
            navigationActions.createAlert(
                new Alert(i18n.t('notification.error.title'), i18n.t('notification.error.description'), 'error')
            )
        );
        
    }
    return response.data;
}

export function handleResponse(response) {
    return response.data;
}
