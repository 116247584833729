import React, { Component } from 'react';

import Loader from '../ui/components/Loader';
import { activateInputs } from '../../utils/formUtils';

export default class AuthenticationContainer extends Component {
    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    render() {
        const {  header, link, isFetching, children } = this.props;

        const Visual = () => (
            <div className="visual">
                <img src={"/images/login-background.jpg"} alt="login background" />
                <div className="content">
                    <h1>{header}</h1>
                    {link}
                </div>
            </div>
        );

        if (isFetching) {
            return (
                <div className="start">
                    <Visual />
                    <div className="action">
                        <div className="absolute-center">
                            <Loader />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="start">
                <Visual />
                <div className="action">
                    <div className="absolute-center">
                        <div className="content">
                            <img
                                className="logo"
                                src={'/images/dontrucking.png'}
                                alt={`Don Trucking logo`}
                            />
                            <h1>{header}</h1>
                            {link}
                        </div>

                        {children}
                    </div>
                </div>
            </div>
        );
    }
}
