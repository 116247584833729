import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import SettingsPage from '@components/settings/SettingsPage';
import * as authActions from '@actions/authActions';
import * as settingsActions from '@actions/settingsActions';

class SettingsContainer extends Component {
    componentDidMount() {
        const { getSettings } = this.props;

        getSettings();
    }

    render() {
        const {
            getSettings,
            updateSettings,
            updateUser,
            updatePassword,
            getSessions,
            logoutAllOthers,
            changeValue,
            ...props
        } = this.props;

        const { t } = this.props;

        return (
            <SettingsPage
                {...props}
                onChange={changeValue}
                getSettings={() => getSettings()}
                updateSettings={(settings) => updateSettings(t, settings)}
                updateUser={(user) => updateUser(t, user)}
                updatePassword={(currentPassword, newPassword, repeatPassword) =>
                    updatePassword(currentPassword, newPassword, repeatPassword)
                }
                getSessions={() => getSessions()}
                logoutAllOthers={() => logoutAllOthers()}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        error: state.auth.error,
        isFetchingUser: state.auth.isFetchingUser,

        isFetchingSessions: state.auth.isFetchingSessions,
        session: state.auth.session,
        sessions: state.auth.sessions,

        ...state.settings,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(settingsActions.changeValue(name, value)),
        getSettings: () => dispatch(settingsActions.getSettings()),
        updateSettings: (t, settings) => dispatch(settingsActions.updateSettings(t, settings)),

        updateUser: (t, user) => dispatch(authActions.update(t, user)),
        updatePassword: (currentPassword, newPassword, repeatPassword) =>
            dispatch(authActions.updatePassword(currentPassword, newPassword, repeatPassword)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(SettingsContainer));
