import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import SimpleReactValidator from 'simple-react-validator';
import AuthenticationContainer from './AuthenticationContainer';

export default class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onLogin } = this.props;

        if (this.validator.allValid()) {
            onLogin();
        } else {
            this.validator.showMessages();
        }
    }

    render() {
        const { onChange, t, username, password } = this.props;

        return (
            <AuthenticationContainer
                header={t('login.header')}
                link={false ? <Link to={'/register'}>{t('login.noAccount')}</Link> : null}
                {...this.props}
            >
                <form method="post" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <input
                                type="text"
                                value={username}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    onChange('username', value, event);
                                }}
                            />
                            <label>{t('form.label.username')}</label>
                        </div>
                        {this.validator.message('username', username, 'required')}
                    </div>
                    <div className="input-group">
                        <div className="input-group no-margin-top">
                            <input
                                type="password"
                                value={password}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    onChange('password', value, event);
                                }}
                            />
                            <label>{t('form.label.password')}</label>
                        </div>
                        {this.validator.message('password', password, 'required')}
                    </div>
                    <div className="input-group right">
                        <div className="msg bottom">{this.props.error}</div>
                        <input type="submit" value={t('login.submit')} onClick={(e) => e.stopPropagation()} />
                        <div className="input-group">
                            <Link to="/forgot">{t('login.forgot')}</Link>
                        </div>
                    </div>
                </form>
            </AuthenticationContainer>
        );
    }
}
