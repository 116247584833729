
export const motConstants = {
    MOT_CHANGE_VALUE: "MOT_CHANGE_VALUE",

    GET_MOT_STARTED: "GET_MOT_STARTED",
    GET_MOT_SUCCESS: "GET_MOT_SUCCESS",
    GET_MOT_FAILURE: "GET_MOT_FAILURE",

    GET_MOTS_STARTED: "GET_MOTS_STARTED",
    GET_MOTS_SUCCESS: "GET_MOTS_SUCCESS",
    GET_MOTS_FAILURE: "GET_MOTS_FAILURE",

    SEARCH_MOTS_STARTED: "SEARCH_MOTS_STARTED",
    SEARCH_MOTS_SUCCESS: "SEARCH_MOTS_SUCCESS",
    SEARCH_MOTS_FAILURE: "SEARCH_MOTS_FAILURE",

    CREATING_MOT_STARTED: "CREATING_MOT_STARTED",
    CREATING_MOT_SUCCESS: "CREATING_MOT_SUCCESS",
    CREATING_MOT_FAILURE: "CREATING_MOT_FAILURE",

    UPDATING_MOT_STARTED: "UPDATING_MOT_STARTED",
    UPDATING_MOT_SUCCESS: "UPDATING_MOT_SUCCESS",
    UPDATING_MOT_FAILURE: "UPDATING_MOT_FAILURE",

    DELETE_MOT_STARTED: "DELETE_MOT_STARTED",
    DELETE_MOT_SUCCESS: "DELETE_MOT_SUCCESS",
    DELETE_MOT_FAILURE: "DELETE_MOT_FAILURE",
};
