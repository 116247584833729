import { authConstants } from '../constants';
import i18n from '../../i18n';
import * as authApi from '@api/authApi';

import * as navigationActions from './navigationActions';
import Alert from '@models/Alert';

export const changeValue = (name, value) => ({
    type: authConstants.CHANGE_VALUE,
    name,
    value,
});

/**
 * Login actions
 */
export const changeLoginValue = (name, value) => ({
    type: authConstants.LOGIN_FORM_CHANGE_VALUE,
    name,
    value,
});

export const startLogin = () => ({
    type: authConstants.LOGIN_FORM_STARTED,
});

export const loginSuccess = (result) => ({
    type: authConstants.LOGIN_FORM_SUCCESS,
    ...result,
});

export const loginFailure = (error) => ({
    type: authConstants.LOGIN_FORM_FAILURE,
    ...error,
});

export const login = (username, password) => {
    return (dispatch) => {
        dispatch(startLogin());

        const promise = authApi.login(username, password);

        promise
            .then((result) => {
                console.log('succesfull');
                dispatch(loginSuccess(result));
            })
            .catch((error) => {
                console.log(error);
                dispatch(loginFailure(error));
                dispatch(
                    navigationActions.createAlert(
                        new Alert(
                            i18n.t('notification.login.error.title'),
                            i18n.t('notification.login.error.description'),
                            'error'
                        )
                    )
                );
            });

        return promise;
    };
};

/**
 * Register actions
 */
export const changeRegisterValue = (name, value) => ({
    type: authConstants.REGISTER_FORM_CHANGE_VALUE,
    name,
    value,
});

export const startRegister = () => ({
    type: authConstants.REGISTER_FORM_STARTED,
});

export const registerSuccess = (result) => ({
    type: authConstants.REGISTER_FORM_SUCCESS,
    ...result,
});

export const registerFailure = (error) => ({
    type: authConstants.REGISTER_FORM_FAILURE,
    ...error,
});

export const register = (username, email, password, repeatPassword, terms, newsletter, recaptcha) => {
    return (dispatch) => {
        dispatch(startRegister());

        const promise = authApi.register(username, email, password, repeatPassword, terms, newsletter, recaptcha);

        promise
            .then((result) => {
                dispatch(registerSuccess(result));
            })
            .catch((error) => {
                dispatch(registerFailure(error));
            });

        return promise;
    };
};

/**
 * Confirm actions
 */
export const startEmailConfirmation = () => ({
    type: authConstants.CONFIRM_EMAIL_STARTED,
});

export const emailConfirmationSuccess = (result) => ({
    type: authConstants.CONFIRM_EMAIL_SUCCESS,
    ...result,
});

export const emailConfirmationFailure = (error) => ({
    type: authConstants.CONFIRM_EMAIL_FAILURE,
    ...error,
});

export const confirmEmail = (user, token) => {
    return (dispatch) => {
        dispatch(startEmailConfirmation());

        const promise = authApi.confirmEmail(user, token);

        promise
            .then((result) => {
                dispatch(emailConfirmationSuccess(result));
                dispatch(
                    navigationActions.createAlert(
                        new Alert(
                            i18n.t('notification.confirm.success.title'),
                            i18n.t('notification.confirm.success.description'),
                            'success'
                        )
                    )
                );
            })
            .catch((error) => {
                dispatch(emailConfirmationFailure(error));
                dispatch(
                    navigationActions.createAlert(
                        new Alert(
                            i18n.t('notification.confirm.error.title'),
                            i18n.t('notification.confirm.error.description'),
                            'error'
                        )
                    )
                );
            });

        return promise;
    };
};

/**
 * Forgot actions
 */
export const changeForgotValue = (name, value) => ({
    type: authConstants.FORGOT_FORM_CHANGE_VALUE,
    name,
    value,
});

export const startForgot = () => ({
    type: authConstants.FORGOT_FORM_STARTED,
});

export const forgotSuccess = (result) => ({
    type: authConstants.FORGOT_FORM_SUCCESS,
    ...result,
});

export const forgotFailure = (error) => ({
    type: authConstants.FORGOT_FORM_FAILURE,
    ...error,
});

export const forgot = (email) => {
    return (dispatch) => {
        dispatch(startForgot());

        const promise = authApi.forgot(email);

        promise
            .then((result) => {
                dispatch(forgotSuccess(result));
            })
            .catch((error) => {
                dispatch(forgotFailure(error));
            });

        return promise;
    };
};

/**
 * Reset actions
 */
export const changeResetValue = (name, value) => ({
    type: authConstants.RESET_FORM_CHANGE_VALUE,
    name,
    value,
});

export const startReset = () => ({
    type: authConstants.RESET_FORM_STARTED,
});

export const resetSuccess = (result) => ({
    type: authConstants.RESET_FORM_SUCCESS,
    ...result,
});

export const resetFailure = (error) => ({
    type: authConstants.RESET_FORM_FAILURE,
    ...error,
});

export const reset = (user, token, password, repeatPassword) => {
    return (dispatch) => {
        dispatch(startForgot());

        const promise = authApi.reset(user, token, password, repeatPassword);

        promise
            .then((result) => {
                dispatch(forgotSuccess(result));
                dispatch(
                    navigationActions.createAlert(
                        new Alert(
                            i18n.t('notification.reset.success.title'),
                            i18n.t('notification.reset.success.description'),
                            'success'
                        )
                    )
                );
            })
            .catch((error) => {
                dispatch(forgotFailure(error));
                dispatch(
                    navigationActions.createAlert(
                        new Alert(
                            i18n.t('notification.reset.error.title'),
                            i18n.t('notification.reset.error.description'),
                            'error'
                        )
                    )
                );
            });

        return promise;
    };
};

/**
 * Update user actions
 */
export const updateValue = (name, value) => ({
    type: authConstants.UPDATE_USER_FORM_VALUE,
    name,
    value,
});

export const startUpdate = () => ({
    type: authConstants.UPDATE_USER_STARTED,
});

export const updateSuccess = (result) => ({
    type: authConstants.UPDATE_USER_SUCCESS,
    ...result,
});

export const updateFailure = (error) => ({
    type: authConstants.UPDATE_USER_FAILURE,
    ...error,
});

export const update = (t, user) => {
    return (dispatch) => {
        dispatch(startUpdate());

        const promise = authApi.update(user);

        promise
            .then((result) => {
                dispatch(updateSuccess(result));
            })
            .catch((error) => {
                dispatch(updateFailure(error));
            });

        return promise;
    };
};

/**
 * Update password actions
 */
export const changeUpdatePassword = (name, value) => ({
    type: authConstants.UPDATE_PASSWORD_FORM_VALUE,
    name,
    value,
});

export const startUpdatePassword = () => ({
    type: authConstants.UPDATE_PASSWORD_STARTED,
});

export const updatePasswordSuccess = (result) => ({
    type: authConstants.UPDATE_PASSWORD_SUCCESS,
    ...result,
});

export const updatePasswordFailure = (error) => ({
    type: authConstants.UPDATE_PASSWORD_FAILURE,
    ...error,
});

export const updatePassword = (currentPassword, newPassword, repeatPassword) => {
    return (dispatch) => {
        dispatch(startUpdatePassword());

        const promise = authApi.updatePassword(currentPassword, newPassword, repeatPassword);

        promise
            .then((result) => {
                dispatch(updatePasswordSuccess(result));
            })
            .catch((error) => {
                dispatch(updatePasswordFailure(error));
            });

        return promise;
    };
};

/**
 * Logout actions
 */
export const startLogout = () => ({
    type: authConstants.LOGOUT_STARTED,
});

export const logoutSuccess = (result) => ({
    type: authConstants.LOGOUT_SUCCESS,
    ...result,
});

export const logoutFailure = (error) => ({
    type: authConstants.LOGOUT_FAILURE,
    ...error,
});

export const logout = () => {
    return (dispatch) => {
        dispatch(startLogout());

        const promise = authApi.logout();

        promise
            .then((result) => {
                dispatch(logoutSuccess(result));
            })
            .catch((error) => {
                dispatch(logoutFailure(error));
            });

        return promise;
    };
};
