
export const businessConstants = {
    BUSINESS_CHANGE_VALUE: "BUSINESS_CHANGE_VALUE",

    GET_BUSINESS_STARTED: "GET_BUSINESS_STARTED",
    GET_BUSINESS_SUCCESS: "GET_BUSINESS_SUCCESS",
    GET_BUSINESS_FAILURE: "GET_BUSINESS_FAILURE",

    GET_BUSINESSES_STARTED: "GET_BUSINESSES_STARTED",
    GET_BUSINESSES_SUCCESS: "GET_BUSINESSES_SUCCESS",
    GET_BUSINESSES_FAILURE: "GET_BUSINESSES_FAILURE",

    SEARCH_BUSINESSES_STARTED: "SEARCH_BUSINESSES_STARTED",
    SEARCH_BUSINESSES_SUCCESS: "SEARCH_BUSINESSES_SUCCESS",
    SEARCH_BUSINESSES_FAILURE: "SEARCH_BUSINESSES_FAILURE",

    CREATING_BUSINESS_STARTED: "CREATING_BUSINESS_STARTED",
    CREATING_BUSINESS_SUCCESS: "CREATING_BUSINESS_SUCCESS",
    CREATING_BUSINESS_FAILURE: "CREATING_BUSINESS_FAILURE",

    UPDATING_BUSINESS_STARTED: "UPDATING_BUSINESS_STARTED",
    UPDATING_BUSINESS_SUCCESS: "UPDATING_BUSINESS_SUCCESS",
    UPDATING_BUSINESS_FAILURE: "UPDATING_BUSINESS_FAILURE",

    DELETE_BUSINESS_STARTED: "DELETE_BUSINESS_STARTED",
    DELETE_BUSINESS_SUCCESS: "DELETE_BUSINESS_SUCCESS",
    DELETE_BUSINESS_FAILURE: "DELETE_BUSINESS_FAILURE",
};
