import axios from 'axios';
import Promise from 'promise';
import { store } from './index';
import { handleError, handleResponse } from './utils/responseMiddleware';

const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        // 'Access-Control-Allow-Origin': '*',
    },
};

const instance = axios.create(defaultOptions);


instance.interceptors.request.use(
    (config) => {
        const storeState = store.getState();
        const token = storeState.auth.bearer;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        config.headers['X-Referer'] = window.location.hostname;
        config.headers['X-TenantID'] = 1
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(handleResponse(response));
        } else {
            return Promise.reject(handleError(response));
        }
    },
    (error) => {
        return Promise.reject(handleError(error));
    }
);

export default instance;
