import React, { Component } from "react";
import Loader from "@uicomponents/Loader";

export default class LogoutPage extends Component {
    componentDidMount() {
        const { onLogout } = this.props;
        onLogout();
    }

    render() {
        const { t } = this.props;
        return (
            <div className="fullpage">
                <div className="absolute-center">
                    <Loader text={t("bye")} />
                </div>
            </div>
        );
    }
}
