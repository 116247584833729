
export const warehouseConstants = {
    WAREHOUSE_CHANGE_VALUE: "WAREHOUSE_CHANGE_VALUE",

    GET_WAREHOUSE_STARTED: "GET_WAREHOUSE_STARTED",
    GET_WAREHOUSE_SUCCESS: "GET_WAREHOUSE_SUCCESS",
    GET_WAREHOUSE_FAILURE: "GET_WAREHOUSE_FAILURE",

    GET_WAREHOUSES_STARTED: "GET_WAREHOUSES_STARTED",
    GET_WAREHOUSES_SUCCESS: "GET_WAREHOUSES_SUCCESS",
    GET_WAREHOUSES_FAILURE: "GET_WAREHOUSES_FAILURE",

    SEARCH_WAREHOUSES_STARTED: "SEARCH_WAREHOUSES_STARTED",
    SEARCH_WAREHOUSES_SUCCESS: "SEARCH_WAREHOUSES_SUCCESS",
    SEARCH_WAREHOUSES_FAILURE: "SEARCH_WAREHOUSES_FAILURE",

    CREATING_WAREHOUSE_STARTED: "CREATING_WAREHOUSE_STARTED",
    CREATING_WAREHOUSE_SUCCESS: "CREATING_WAREHOUSE_SUCCESS",
    CREATING_WAREHOUSE_FAILURE: "CREATING_WAREHOUSE_FAILURE",

    UPDATING_WAREHOUSE_STARTED: "UPDATING_WAREHOUSE_STARTED",
    UPDATING_WAREHOUSE_SUCCESS: "UPDATING_WAREHOUSE_SUCCESS",
    UPDATING_WAREHOUSE_FAILURE: "UPDATING_WAREHOUSE_FAILURE",

    DELETE_WAREHOUSE_STARTED: "DELETE_WAREHOUSE_STARTED",
    DELETE_WAREHOUSE_SUCCESS: "DELETE_WAREHOUSE_SUCCESS",
    DELETE_WAREHOUSE_FAILURE: "DELETE_WAREHOUSE_FAILURE",
};
