
export const reminderConstants = {
    REMINDER_CHANGE_VALUE: "REMINDER_CHANGE_VALUE",

    GET_REMINDER_STARTED: "GET_REMINDER_STARTED",
    GET_REMINDER_SUCCESS: "GET_REMINDER_SUCCESS",
    GET_REMINDER_FAILURE: "GET_REMINDER_FAILURE",

    GET_REMINDERS_STARTED: "GET_REMINDERS_STARTED",
    GET_REMINDERS_SUCCESS: "GET_REMINDERS_SUCCESS",
    GET_REMINDERS_FAILURE: "GET_REMINDERS_FAILURE",

    SEARCH_REMINDERS_STARTED: "SEARCH_REMINDERS_STARTED",
    SEARCH_REMINDERS_SUCCESS: "SEARCH_REMINDERS_SUCCESS",
    SEARCH_REMINDERS_FAILURE: "SEARCH_REMINDERS_FAILURE",

    CREATING_REMINDER_STARTED: "CREATING_REMINDER_STARTED",
    CREATING_REMINDER_SUCCESS: "CREATING_REMINDER_SUCCESS",
    CREATING_REMINDER_FAILURE: "CREATING_REMINDER_FAILURE",

    UPDATING_REMINDER_STARTED: "UPDATING_REMINDER_STARTED",
    UPDATING_REMINDER_SUCCESS: "UPDATING_REMINDER_SUCCESS",
    UPDATING_REMINDER_FAILURE: "UPDATING_REMINDER_FAILURE",

    DELETE_REMINDER_STARTED: "DELETE_REMINDER_STARTED",
    DELETE_REMINDER_SUCCESS: "DELETE_REMINDER_SUCCESS",
    DELETE_REMINDER_FAILURE: "DELETE_REMINDER_FAILURE",
};
