import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as navigationActions from '@actions/navigationActions';

import Loader from '@uicomponents/Loader';
import NoData from '@uicomponents/NoData';

import FilterView from './FilterView';

class TableView extends Component {
    constructor(props) {
        super(props);

        this.searchTimeout = null;
        this.searchRef = React.createRef();

        const selectedOptions =
            localStorage &&
            localStorage.getItem(`${(props.title || this.props.createNewLabel || '').toLowerCase()}-selected-fields`)
                ? JSON.parse(
                      localStorage.getItem(
                          `${(props.title || this.props.createNewLabel || '').toLowerCase()}-selected-fields`
                      )
                  )
                : props.selectedOptions;

        const filters =
            localStorage &&
            localStorage.getItem(`${(props.title || this.props.createNewLabel || '').toLowerCase()}-filters`)
                ? JSON.parse(
                      localStorage.getItem(`${(props.title || this.props.createNewLabel || '').toLowerCase()}-filters`)
                  )
                : props.filters;

        const selectedSortOption =
            localStorage &&
            localStorage.getItem(`${(props.title || this.props.createNewLabel || '').toLowerCase()}-selectedSortOption`)
                ? localStorage.getItem(
                      `${(props.title || this.props.createNewLabel || '').toLowerCase()}-selectedSortOption`
                  )
                : null;

        this.state = {
            searchIsFocused: false,
            searchCaretPosition: {
                start: 0,
                end: 0,
            },
            selectedOptions: selectedOptions,
            selectOptionsMenu: false,
            filters: props.filters,
            filtersMenu: false,
            selected: this.props.selected ? this.props.selected : [],
            selectedSortOption: selectedSortOption,
            sortOptions: props.sortOptions || [],
            sortMenu: false,
        };
    }

    static defaultProps = {
        value: [],
        options: [],
        actions: [],
        filters: [],
        sortOptions: [],
        noDataLabel: 'No data',
        createNewLabel: '+ new',
        canSelect: false,
        downloadable: false,
        onFiltersChange: () => null,
    };

    byString = (o, s) => {
        try {
            s = s.replace(/\[(\w+)\]/g, '.$1');
            s = s.replace(/^\./, '');
            var a = s.split('.');
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i];
                if (k in o) {
                    o = o[k];
                } else {
                    return;
                }
            }
            return o;
        } catch (e) {
            return undefined;
        }
    };

    isFunction = (value) => {
        if (value instanceof Function) {
            return true;
        }
        return false;
    };

    handleScroll = () => {
        const { onScrolledToBottom, isLoading } = this.props;
        if (window.innerHeight + window.scrollY >= document.querySelector('.page').offsetHeight - 50 && !isLoading) {
            onScrolledToBottom && onScrolledToBottom();
        }
    };

    onSearchChangeHandler(e) {
        this.setState({
            searchCaretPosition: {
                start: e.currentTarget.selectionStart,
                end: e.currentTarget.selectionEnd,
            },
        });
    }

    hasParentNode(element, classNames) {
        let parentNode = element.parentNode;
        if (parentNode) {
            if (typeof parentNode.className === 'string' || parentNode.className instanceof String) {
                if (parentNode.className.includes(classNames)) {
                    return true;
                }
            }
            return this.hasParentNode(parentNode, classNames);
        }
        return false;
    }

    getValue = (value, option) => {
        const { t } = this.props;
        if (this.isFunction(option.selector)) {
            return option.selector(value);
        }

        switch (option.type) {
            case 'string':
            case 'link':
                return this.byString(value, option.selector);
            case 'boolean':
                return this.byString(value, option.selector) === true ? t('yes') : t('no');
            case 'array':
                return this.byString(value, option.selector)
                    .map((selectedValue) => this.byString(selectedValue, option.valueSelector))
                    .join(', ');
            case 'image':
                return this.byString(value, option.selector)
                    ? this.byString(value, option.selector)
                    : option.defaultValue;
            default:
                return '';
        }
    };

    getCSVData = () => {
        let csvData = [];
        const { values, options } = this.props;
        const { selectedOptions } = this.state;
        values.forEach((value, valueIndex) => {
            selectedOptions.forEach((selectedOption, optionIndex) => {
                const option = options.find((option) => selectedOption === option.name);
                if (!option) return;
                if (valueIndex < 1) {
                    if (optionIndex < 1) {
                        csvData[valueIndex] = [];
                    }
                    csvData[valueIndex][optionIndex] = option.header;
                }
                if (optionIndex < 1) {
                    csvData[valueIndex + 1] = [];
                }
                csvData[valueIndex + 1][optionIndex] = this.getValue(value, option);
            });
        });
        return csvData;
    };

    convertFiltersToSearchParameters = (filters) => {
        let searchParameters = {};

        filters.forEach((filter) => {
            if (filter.type === 'sorting') {
                searchParameters[filter.name] = [filter];
            } else if (filter.value) {
                searchParameters[filter.name] = filter.value;
            }
        });
        return searchParameters;
    };

    closeMenus = (e) => {
        const target = e.target;
        const { selectOptionsMenu, sortMenu } = this.state;
        if (selectOptionsMenu || sortMenu) {
            if (!this.hasParentNode(target, 'action')) {
                this.setState({
                    selectOptionsMenu: false,
                    sortMenu: false,
                });
            }
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);

        const { searchIsFocused, searchCaretPosition } = this.state;
        if (searchIsFocused) {
            this.searchRef.current.focus();
            this.searchRef.current.setSelectionRange(searchCaretPosition.start, searchCaretPosition.end);
        }

        document.addEventListener('click', this.closeMenus);
        document.addEventListener('keyup', this.handleKeyBindings);
    }

    componentDidUpdate() {
        const { searchIsFocused, searchCaretPosition } = this.state;
        if (searchIsFocused) {
            this.searchRef.current.focus();
            this.searchRef.current.setSelectionRange(searchCaretPosition.start, searchCaretPosition.end);
        }
    }

    componentWillUnmount() {
        this.searchRef = null;

        window.removeEventListener('scroll', this.handleScroll);
        document.removeEventListener('click', this.closeMenus);
        document.removeEventListener('keyup', this.handleKeyBindings);
    }

    render() {
        const {
            t,
            title,
            subTitle,
            noDataLabel,
            onCreate,
            createNewLabel,
            values,
            isLoading,
            isLoadingFrom,
            options,
            actions,
            searchEnabled,
            searchPlaceholder,
            onFiltersChange,
            rowOnClick,
            optionsSelectable,
            downloadable,
            small,
        } = this.props;

        const {
            searchQuery,
            selectedOptions,
            selected,
            selectOptionsMenu,
            filters,
            sortOptions,
            selectedSortOption,
            sortMenu,
        } = this.state;

        return (
            <div className={`table${small ? ' small' : ''}`} key={`${values.length}-${isLoading}-${title}`}>
                <div className="flex-container">
                    {title ? <h1 className="no-margin-top">{title}</h1> : null}
                    {subTitle ? <p className="self-center no-margin-top">{subTitle}</p> : null}
                </div>
                <div className="top-functions flex-container">
                    <div className="flex-container one action-list">
                        <div className="search-block self-center">
                            {searchEnabled ? (
                                <>
                                    <input
                                        ref={this.searchRef}
                                        type="text"
                                        className="search"
                                        value={searchQuery}
                                        placeholder={searchPlaceholder}
                                        onBlur={(e) => {
                                            this.setState({
                                                searchIsFocused: false,
                                            });
                                            this.onSearchChangeHandler(e);
                                        }}
                                        onChange={(e) => {
                                            const searchQuery = e.target.value === '' ? null : e.target.value;
                                            this.onSearchChangeHandler(e);
                                            this.setState({
                                                searchQuery: searchQuery,
                                                searchIsFocused: true,
                                            });
                                            if (this.searchTimeout) clearTimeout(this.searchTimeout);
                                            this.searchTimeout = setTimeout(() => {
                                                if (filters.filter((f) => f.name === 'query').length === 1) {
                                                    const newFilters = [...filters];
                                                    const newFilter =
                                                        newFilters[filters.findIndex((f) => f.name === 'query')];

                                                    newFilter.value = searchQuery;
                                                    newFilters[filters.findIndex((f) => f.name === 'query')] =
                                                        newFilter;
                                                    this.setState({
                                                        filters: newFilters,
                                                    });

                                                    // Save to store
                                                    if (localStorage) {
                                                        localStorage.setItem(
                                                            `${title.toLowerCase()}-filters`,
                                                            JSON.stringify(newFilters)
                                                        );
                                                    }

                                                    if (onFiltersChange)
                                                        onFiltersChange(
                                                            this.convertFiltersToSearchParameters(newFilters)
                                                        );
                                                } else {
                                                    const newFilters = [...filters];
                                                    newFilters.push({
                                                        name: 'query',
                                                        type: 'string',
                                                        value: searchQuery,
                                                    });
                                                    this.setState({
                                                        filters: newFilters,
                                                    });

                                                    // Save to store
                                                    if (localStorage) {
                                                        localStorage.setItem(
                                                            `${title.toLowerCase()}-filters`,
                                                            JSON.stringify(newFilters)
                                                        );
                                                    }

                                                    if (onFiltersChange)
                                                        onFiltersChange(
                                                            this.convertFiltersToSearchParameters(newFilters)
                                                        );
                                                }
                                            }, 800);
                                        }}
                                    />
                                </>
                            ) : null}
                        </div>
                        {optionsSelectable ||
                        filters.length > 0 ||
                        downloadable ||
                        (sortOptions && sortOptions.length > 0) ? (
                            <div className="actions action-icons self-center">
                                {optionsSelectable && (
                                    <div className="action">
                                        <div
                                            className="action-icon"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                    selectOptionsMenu: !selectOptionsMenu,
                                                    sortMenu: false,
                                                });
                                            }}
                                        >
                                            <ReactSVG src="/icons/settings.svg" />
                                        </div>
                                        <div className={`action-menu${selectOptionsMenu ? ' show' : ''}`}>
                                            <div className="scrollable menu-container">
                                                {options.map((option) => (
                                                    <div
                                                        className="action-item flex-container"
                                                        key={`action-item-${option.name}${
                                                            selectedOptions.includes(option.name) ? '-selected' : ''
                                                        }`}
                                                    >
                                                        <input
                                                            className="self-center"
                                                            type="checkbox"
                                                            value="selected"
                                                            id={`option-action-item-${option.name}`}
                                                            checked={selectedOptions.includes(option.name)}
                                                            onChange={() => null}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                const newSelectedOptions = selectedOptions.includes(
                                                                    option.name
                                                                )
                                                                    ? selectedOptions.filter((o) => o !== option.name)
                                                                    : options
                                                                          .filter(
                                                                              (o) =>
                                                                                  option.name === o.name ||
                                                                                  selectedOptions.includes(o.name)
                                                                          )
                                                                          .map((o) => o.name);

                                                                this.setState({
                                                                    selectedOptions: newSelectedOptions,
                                                                });

                                                                // Save to store
                                                                if (localStorage) {
                                                                    localStorage.setItem(
                                                                        `${title.toLowerCase()}-selected-fields`,
                                                                        JSON.stringify(newSelectedOptions)
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        <label
                                                            className="name normal"
                                                            htmlFor={`option-action-item-${option.name}`}
                                                        >
                                                            {option.header || option.name}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {sortOptions && sortOptions.length > 0 && (
                                    <div className="action">
                                        <div
                                            className="action-icon"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({
                                                    selectOptionsMenu: false,
                                                    sortMenu: !sortMenu,
                                                });
                                            }}
                                        >
                                            <ReactSVG src="/icons/sort.svg" />
                                        </div>
                                        <div className={`action-menu${sortMenu ? ' show' : ''}`}>
                                            <div className="scrollable menu-container">
                                                {sortOptions.map((option) => (
                                                    <div
                                                        className="action-item flex-container"
                                                        key={`action-item-${option.name}${
                                                            selectedSortOption === option.name ? '-selected' : ''
                                                        }`}
                                                    >
                                                        <input
                                                            className="self-center"
                                                            type="radio"
                                                            value="selected"
                                                            id={`option-sort-item-${option.name}`}
                                                            checked={selectedSortOption === option.name}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();

                                                                const newFilters = [
                                                                    ...[...filters].filter(
                                                                        (filter) => filter.type !== 'sorting'
                                                                    ),
                                                                    {
                                                                        type: 'sorting',
                                                                        name: 'sortBy',
                                                                        fields: option.fields,
                                                                        direction: option.direction,
                                                                    },
                                                                ];

                                                                this.setState({
                                                                    sortMenu: false,
                                                                    selectedSortOption: option.name,
                                                                    filters: newFilters,
                                                                });

                                                                onFiltersChange(
                                                                    this.convertFiltersToSearchParameters(newFilters)
                                                                );

                                                                // Save to store
                                                                if (localStorage) {
                                                                    localStorage.setItem(
                                                                        `${title.toLowerCase()}-selectedSortOption`,
                                                                        option.name
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        <label
                                                            className="name normal"
                                                            htmlFor={`option-sort-item-${option.name}`}
                                                        >
                                                            {option.direction === 'asc' ? '↑ ' : '↓ '}
                                                            {option.header || option.name}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {downloadable && (
                                    <div className="action">
                                        <div className="action-icon">
                                            <CSVLink
                                                data={this.getCSVData()}
                                                filename={`${(title || 'data').toLowerCase()}-export.csv`}
                                            >
                                                <ReactSVG src="/icons/download.svg" />
                                            </CSVLink>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </div>
                    <div className="self-center buttons create-new">
                        {onCreate && <button onClick={onCreate}>{createNewLabel}</button>}
                    </div>
                </div>
                <div className="table-view scrollable__x">
                    <div className="table-header">
                        {selectedOptions.map((selectedOption) => {
                            const option = options.find((option) => selectedOption === option.name);
                            if (!option) return;
                            return (
                                <div
                                    className={`table-header-column ${option.size}`}
                                    key={`selected-option${selectedOption}`}
                                >
                                    {option.header}
                                </div>
                            );
                        })}
                        {!small && actions.map((action) => (
                            <div
                                className="table-column action"
                                key={`action-header-${
                                    this.isFunction(action.name) ? action.name(action) : action.name
                                }`}
                            ></div>
                        ))}
                    </div>
                    {isLoading && ((values && values.length < 1) || isLoadingFrom === 0) ? (
                        <Loader />
                    ) : values && values.length < 1 ? (
                        <div className="not-found-action-box">
                            <NoData>{noDataLabel}</NoData>
                        </div>
                    ) : (
                        <>
                            <div className="table-items">
                                {values.map((value) => (
                                    <div
                                        className={`table-row${rowOnClick ? ' table-row-clickable' : ''}${
                                            selected.includes(value.id) ? ' table-row--selected' : ''
                                        }`}
                                        onClick={(e) => rowOnClick && rowOnClick(e, value)}
                                        key={`table-row${value.id}${selected.includes(value.id) ? '-selected' : ''} `}
                                    >
                                        {selectedOptions.map((selectedOption) => {
                                            const option = options.find((option) => selectedOption === option.name);
                                            if (!option) return;

                                            switch (option.type) {
                                                case 'string':
                                                case 'boolean':
                                                case 'array':
                                                    return (
                                                        <div
                                                            className={`table-column ${option.size}`}
                                                            onClick={option.onClick}
                                                            key={`${value.id}-${option.name}`}
                                                        >
                                                            {this.getValue(value, option)}
                                                        </div>
                                                    );
                                                case 'image':
                                                    return (
                                                        <div
                                                            className={`table-column ${option.size}`}
                                                            onClick={option.onClick}
                                                            key={`${value.id}-${option.name}`}
                                                        >
                                                            <img src={this.getValue(value, option)} alt="" />
                                                        </div>
                                                    );
                                                case 'link':
                                                    return (
                                                        <div
                                                            className={`table-column ${option.size}`}
                                                            onClick={option.onClick}
                                                            key={`${value.id}-${option.name}`}
                                                        >
                                                            <a
                                                                href={this.getValue(value, option)}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {this.getValue(value, option)}
                                                            </a>
                                                        </div>
                                                    );
                                                default:
                                                    return (
                                                        <div
                                                            key={`${value.id}-${option.name}`}
                                                            className={`table-column ${option.size}`}
                                                        ></div>
                                                    );
                                            }
                                        })}
                                        {!small && actions.map((action) => (
                                            <div
                                                className="table-column action"
                                                key={`${action.name}-action-${value.id}`}
                                                disabled={
                                                    !action.enabled ||
                                                    (this.isFunction(action.enabled) && !action.enabled(value))
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    action.action && action.action(e, value);
                                                }}
                                            >
                                                {this.isFunction(action.name) ? action.name(value) : action.name}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                            {isLoading && <Loader />}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(TableView));
