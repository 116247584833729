/* Dependencies */

import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import configureStore from './state/store/configureStore.js';
import configureI18n from './i18n';

import IndexContainer from './IndexContainer';

// all CSS being loaded
import "./sass/index.sass"
import 'react-loading-skeleton/dist/skeleton.css';

/**
 *    The base instance of FleetUI
 **/
 export const store = configureStore();
 export const i18n = configureI18n;

export default class FleetUI extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <IndexContainer {...this.props} />}
                </BrowserRouter>
            </Provider>
        );
    }
}

render(<FleetUI />, document.getElementById('transportial'));
