import React, { Component } from "react";
import ReactSVG from "react-svg";
import { Link } from "react-router-dom";

export default class NotFound extends Component {
    componentDidMount() {}

    render() {
        return (
            <div className="absolute-center center error-page">
                <ReactSVG src="/icons/lost.svg" className="error-page__icon" />
                <h1>404 error occurred</h1>
                <p>Can't find this location, please try again</p>
                <Link to="/">Back to main page</Link>
            </div>
        );
    }
}
