
import { motConstants } from "@constants/mot.constants";

const initialState = {
    isFetchingMot: false,
    formMot: null,
    mot: null,

    isFetchingMots: false,
    isFetchingMotsFrom: 0,
    motsTotalResults: 0,
    motsLoaded: 0,
    mots: [],
};

export default function mot(state = initialState, action) {
    switch (action.type) {
        case motConstants.MOT_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case motConstants.GET_MOT_STARTED:
            return { ...state, isFetchingMot: true };
        case motConstants.GET_MOT_FAILURE:
            return { ...state, isFetchingMot: false, mot: null, error: action.message || "" };
        case motConstants.GET_MOT_SUCCESS:
            return {
                ...state,
                isFetchingMot: false,
                mot: action.mot,
            };
            
        case motConstants.GET_MOTS_STARTED:
            return { ...state, isFetchingMots: true,  isFetchingMotsFrom: action.from || 0 };
        case motConstants.GET_MOTS_FAILURE:
            return { ...state, isFetchingMots: false, mots: [], error: action.message || "" };
        case motConstants.GET_MOTS_SUCCESS:
            return {
                ...state,
                isFetchingMots: false,
                mots: action.from > 0 ? [...state.mots, ...action.mots] : action.mots,
                motsLoaded:
                    action.from > 0
                        ? state.mots.length + action.mots.length
                        : action.mots.length,
                motsTotalResults: action.totalResults || 0,
            };

        case motConstants.SEARCH_MOTS_STARTED:
            return { ...state, isFetchingMots: true, isFetchingMotsFrom: action.from || 0  };
        case motConstants.SEARCH_MOTS_FAILURE:
            return { ...state, isFetchingMots: false, mots: [], error: action.message || "" };
        case motConstants.SEARCH_MOTS_SUCCESS:
            return {
                ...state,
                isFetchingMots: false,
                mots: action.from > 0 ? [...state.mots, ...action.mots] : action.mots,
                motsLoaded:
                    action.from > 0
                        ? state.mots.length + action.mots.length
                        : action.mots.length,
                motsTotalResults: action.totalResults || 0,
            };

        case motConstants.CREATING_MOT_STARTED:
            return { ...state, isFetchingMot: true };
        case motConstants.CREATING_MOT_FAILURE:
            return { ...state, isFetchingMot: false, mot: null, error: action.message || "" };
        case motConstants.CREATING_MOT_SUCCESS:
            return {
                ...state,
                isFetchingMot: false,
                mot: action.mot,
                mots: [action.mot, ...state.mots],
            };

        case motConstants.UPDATING_MOT_STARTED:
            return { ...state, isFetchingMot: true };
        case motConstants.UPDATING_MOT_FAILURE:
            return { ...state, isFetchingMot: false, mot: null, error: action.message || "" };
        case motConstants.UPDATING_MOT_SUCCESS:
            return {
                ...state,
                isFetchingMot: false,
                mot: action.mot,
                mots: [...state.mots].map((mot) => {
                    if (action.mot.id === mot.id) {
                        return action.mot;
                    }
                    return mot;
                }),
            };

        case motConstants.DELETE_MOT_STARTED:
            return { ...state, isFetchingMot: true };
        case motConstants.DELETE_MOT_FAILURE:
            return { ...state, isFetchingMot: false, mot: null, message: action.message || "" };
        case motConstants.DELETE_MOT_SUCCESS:
            return {
                ...state,
                isFetchingMot: false,
                mot: action.mot,
                mots: [...state.mots].filter((mot) => action.mot.id !== mot.id),
            };
        default:
            return state;
    }
}    
    