
import { reminderConstants } from "@constants/reminder.constants";

const initialState = {
    isFetchingReminder: false,
    formReminder: null,
    reminder: null,

    isFetchingReminders: false,
    isFetchingRemindersFrom: 0,
    remindersTotalResults: 0,
    remindersLoaded: 0,
    reminders: [],
};

export default function reminder(state = initialState, action) {
    switch (action.type) {
        case reminderConstants.REMINDER_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case reminderConstants.GET_REMINDER_STARTED:
            return { ...state, isFetchingReminder: true };
        case reminderConstants.GET_REMINDER_FAILURE:
            return { ...state, isFetchingReminder: false, reminder: null, error: action.message || "" };
        case reminderConstants.GET_REMINDER_SUCCESS:
            return {
                ...state,
                isFetchingReminder: false,
                reminder: action.reminder,
            };
            
        case reminderConstants.GET_REMINDERS_STARTED:
            return { ...state, isFetchingReminders: true,  isFetchingRemindersFrom: action.from || 0 };
        case reminderConstants.GET_REMINDERS_FAILURE:
            return { ...state, isFetchingReminders: false, reminders: [], error: action.message || "" };
        case reminderConstants.GET_REMINDERS_SUCCESS:
            return {
                ...state,
                isFetchingReminders: false,
                reminders: action.from > 0 ? [...state.reminders, ...action.reminders] : action.reminders,
                remindersLoaded:
                    action.from > 0
                        ? state.reminders.length + action.reminders.length
                        : action.reminders.length,
                remindersTotalResults: action.totalResults || 0,
            };

        case reminderConstants.SEARCH_REMINDERS_STARTED:
            return { ...state, isFetchingReminders: true, isFetchingRemindersFrom: action.from || 0  };
        case reminderConstants.SEARCH_REMINDERS_FAILURE:
            return { ...state, isFetchingReminders: false, reminders: [], error: action.message || "" };
        case reminderConstants.SEARCH_REMINDERS_SUCCESS:
            return {
                ...state,
                isFetchingReminders: false,
                reminders: action.from > 0 ? [...state.reminders, ...action.reminders] : action.reminders,
                remindersLoaded:
                    action.from > 0
                        ? state.reminders.length + action.reminders.length
                        : action.reminders.length,
                remindersTotalResults: action.totalResults || 0,
            };

        case reminderConstants.CREATING_REMINDER_STARTED:
            return { ...state, isFetchingReminder: true };
        case reminderConstants.CREATING_REMINDER_FAILURE:
            return { ...state, isFetchingReminder: false, reminder: null, error: action.message || "" };
        case reminderConstants.CREATING_REMINDER_SUCCESS:
            return {
                ...state,
                isFetchingReminder: false,
                reminder: action.reminder,
                reminders: [action.reminder, ...state.reminders],
            };

        case reminderConstants.UPDATING_REMINDER_STARTED:
            return { ...state, isFetchingReminder: true };
        case reminderConstants.UPDATING_REMINDER_FAILURE:
            return { ...state, isFetchingReminder: false, reminder: null, error: action.message || "" };
        case reminderConstants.UPDATING_REMINDER_SUCCESS:
            return {
                ...state,
                isFetchingReminder: false,
                reminder: action.reminder,
                reminders: [...state.reminders].map((reminder) => {
                    if (action.reminder.id === reminder.id) {
                        return action.reminder;
                    }
                    return reminder;
                }),
            };

        case reminderConstants.DELETE_REMINDER_STARTED:
            return { ...state, isFetchingReminder: true };
        case reminderConstants.DELETE_REMINDER_FAILURE:
            return { ...state, isFetchingReminder: false, reminder: null, message: action.message || "" };
        case reminderConstants.DELETE_REMINDER_SUCCESS:
            return {
                ...state,
                isFetchingReminder: false,
                reminder: action.reminder,
                reminders: [...state.reminders].filter((reminder) => action.reminder.id !== reminder.id),
            };
        default:
            return state;
    }
}    
    