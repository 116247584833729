
import { businessConstants } from "@constants/business.constants";

const initialState = {
    isFetchingBusiness: false,
    formBusiness: null,
    business: null,

    isFetchingBusinesses: false,
    isFetchingBusinessesFrom: 0,
    businessesTotalResults: 0,
    businessesLoaded: 0,
    businesses: [],
};

export default function business(state = initialState, action) {
    switch (action.type) {
        case businessConstants.BUSINESS_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case businessConstants.GET_BUSINESS_STARTED:
            return { ...state, isFetchingBusiness: true };
        case businessConstants.GET_BUSINESS_FAILURE:
            return { ...state, isFetchingBusiness: false, business: null, error: action.message || "" };
        case businessConstants.GET_BUSINESS_SUCCESS:
            return {
                ...state,
                isFetchingBusiness: false,
                business: action.business,
            };
            
        case businessConstants.GET_BUSINESSES_STARTED:
            return { ...state, isFetchingBusinesses: true,  isFetchingBusinessesFrom: action.from || 0 };
        case businessConstants.GET_BUSINESSES_FAILURE:
            return { ...state, isFetchingBusinesses: false, businesses: [], error: action.message || "" };
        case businessConstants.GET_BUSINESSES_SUCCESS:
            return {
                ...state,
                isFetchingBusinesses: false,
                businesses: action.from > 0 ? [...state.businesses, ...action.businesses] : action.businesses,
                businessesLoaded:
                    action.from > 0
                        ? state.businesses.length + action.businesses.length
                        : action.businesses.length,
                businessesTotalResults: action.totalResults || 0,
            };

        case businessConstants.SEARCH_BUSINESSES_STARTED:
            return { ...state, isFetchingBusinesses: true, isFetchingBusinessesFrom: action.from || 0  };
        case businessConstants.SEARCH_BUSINESSES_FAILURE:
            return { ...state, isFetchingBusinesses: false, businesses: [], error: action.message || "" };
        case businessConstants.SEARCH_BUSINESSES_SUCCESS:
            return {
                ...state,
                isFetchingBusinesses: false,
                businesses: action.from > 0 ? [...state.businesses, ...action.businesses] : action.businesses,
                businessesLoaded:
                    action.from > 0
                        ? state.businesses.length + action.businesses.length
                        : action.businesses.length,
                businessesTotalResults: action.totalResults || 0,
            };

        case businessConstants.CREATING_BUSINESS_STARTED:
            return { ...state, isFetchingBusiness: true };
        case businessConstants.CREATING_BUSINESS_FAILURE:
            return { ...state, isFetchingBusiness: false, business: null, error: action.message || "" };
        case businessConstants.CREATING_BUSINESS_SUCCESS:
            return {
                ...state,
                isFetchingBusiness: false,
                business: action.business,
                businesses: [action.business, ...state.businesses],
            };

        case businessConstants.UPDATING_BUSINESS_STARTED:
            return { ...state, isFetchingBusiness: true };
        case businessConstants.UPDATING_BUSINESS_FAILURE:
            return { ...state, isFetchingBusiness: false, business: null, error: action.message || "" };
        case businessConstants.UPDATING_BUSINESS_SUCCESS:
            return {
                ...state,
                isFetchingBusiness: false,
                business: action.business,
                businesses: [...state.businesses].map((business) => {
                    if (action.business.id === business.id) {
                        return action.business;
                    }
                    return business;
                }),
            };

        case businessConstants.DELETE_BUSINESS_STARTED:
            return { ...state, isFetchingBusiness: true };
        case businessConstants.DELETE_BUSINESS_FAILURE:
            return { ...state, isFetchingBusiness: false, business: null, message: action.message || "" };
        case businessConstants.DELETE_BUSINESS_SUCCESS:
            return {
                ...state,
                isFetchingBusiness: false,
                business: action.business,
                businesses: [...state.businesses].filter((business) => action.business.id !== business.id),
            };
        default:
            return state;
    }
}    
    