
import { brandConstants } from '../constants';
import * as brandApi from '@api/brandApi';

export const changeValue = (name, value) => ({
    type: brandConstants.BRAND_CHANGE_VALUE,
    name,
    value,
});

/**
 * Fetching brand
 */
export const startGetBrand = () => ({
    type: brandConstants.GET_BRAND_STARTED,
});

export const getBrandSuccess = (result) => ({
    type: brandConstants.GET_BRAND_SUCCESS,
    brand: result,
});

export const getBrandFailure = (error) => ({
    type: brandConstants.GET_BRAND_FAILURE,
    ...error,
});

export const getBrand = (id) => {
    return (dispatch) => {
        dispatch(startGetBrand());

        const promise = brandApi.get(id);

        promise
            .then((result) => {
                dispatch(getBrandSuccess(result));
            })
            .catch((error) => {
                dispatch(getBrandFailure(error));
            });

        return promise;
    };
};

/**
 * Creating brand
 */
export const changeCreateBrand = (name, value) => ({
    type: brandConstants.CREATING_BRAND_CHANGE_VALUE,
    name,
    value,
});
export const startCreateBrand = () => ({
    type: brandConstants.CREATING_BRAND_STARTED,
});

export const createBrandSuccess = (result, brand) => ({
    type: brandConstants.CREATING_BRAND_SUCCESS,
    brand,
    ...result,
});

export const createBrandFailure = (error) => ({
    type: brandConstants.CREATING_BRAND_FAILURE,
    ...error,
});

export const createBrand = (brand) => {
    return (dispatch) => {
        dispatch(startCreateBrand());

        const promise = brandApi.create(brand);

        promise
            .then((result) => {
                dispatch(createBrandSuccess(result, brand));
            })
            .catch((error) => {
                dispatch(createBrandFailure(error));
            });

        return promise;
    };
};

/**
 * Updating brand
 */
export const changeUpdateBrand = (name, value) => ({
    type: brandConstants.UPDATING_BRAND_CHANGE_VALUE,
    name,
    value,
});
export const startUpdateBrand = () => ({
    type: brandConstants.UPDATING_BRAND_STARTED,
});

export const updateBrandSuccess = (result, brand) => ({
    type: brandConstants.UPDATING_BRAND_SUCCESS,
    brand,
    ...result,
});

export const updateBrandFailure = (error) => ({
    type: brandConstants.UPDATING_BRAND_FAILURE,
    ...error,
});

export const updateBrand = (brand) => {
    return (dispatch) => {
        dispatch(startUpdateBrand());

        const promise = brandApi.update(brand);

        promise
            .then((result) => {
                dispatch(updateBrandSuccess(result, brand));
            })
            .catch((error) => {
                dispatch(updateBrandFailure(error));
            });

        return promise;
    };
};

/**
 * Deleting brand
 */
export const startDeleteBrand = () => ({
    type: brandConstants.DELETE_BRAND_STARTED,
});

export const deleteBrandSuccess = (result, brand) => ({
    type: brandConstants.DELETE_BRAND_SUCCESS,
    brand,
    ...result,
});

export const deleteBrandFailure = (error) => ({
    type: brandConstants.DELETE_BRAND_FAILURE,
    ...error,
});
export const deleteBrand = (brand) => {
    return (dispatch) => {
        dispatch(startDeleteBrand());

        const promise = brandApi.remove(brand);

        promise
            .then((result) => {
                dispatch(deleteBrandSuccess(result, brand));
            })
            .catch((error) => {
                dispatch(deleteBrandFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching brands
 */
export const startGetBrands = (from) => ({
    type: brandConstants.GET_BRANDS_STARTED,
    from: from,
});

export const getBrandsSuccess = (result, from) => ({
    type: brandConstants.GET_BRANDS_SUCCESS,
    from: from,
    brands: result,
});

export const getBrandsFailure = (error) => ({
    type: brandConstants.GET_BRANDS_FAILURE,
    ...error,
});

export const getBrands = (from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetBrands(from));

        const promise = brandApi.latest(from, amount);

        promise
            .then((result) => {
                dispatch(getBrandsSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getBrandsFailure(error));
            });

        return promise;
    };
};

export const searchBrands = (searchParameters = [], from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetBrands(from));

        const promise = brandApi.search(searchParameters, from, amount);

        promise
            .then((result) => {
                dispatch(getBrandsSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getBrandsFailure(error));
            });

        return promise;
    };
};
    
