import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import UpdateUserPage from '../../../components/user/crud/UpdateUserPage.js';

import * as userActions from '../../../state/actions/userActions';
import * as authActions from '../../../state/actions/authActions';
import * as navigationActions from '../../../state/actions/navigationActions';

class UpdateUserContainer extends Component {
    render() {
        const { changeValue, updateUser, inviteUser, popStack, ...props } = this.props;

        return (
            <UpdateUserPage
                {...props}
                onChange={changeValue}
                updateUser={(user) =>
                    updateUser(user).then((response) => {
                        popStack();
                    })
                }
                inviteUser={(user) =>
                    inviteUser(user).then((response) => {
                        popStack();
                    })
                }
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.user,
        profile: state.auth.profile,

        ...state.auth,
        ...state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateUser: (user) => dispatch(userActions.updateUser(user)),
        inviteUser: (user) => dispatch(userActions.inviteUser(user)),
        changeValue: (name, value) => dispatch(userActions.changeValue(name, value)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(UpdateUserContainer));
