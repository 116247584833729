import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import DashboardPage from '@components/dashboard/DashboardPage';

import * as vehicleActions from '@actions/vehicleActions';
import * as navigationActions from '@actions/navigationActions';

class DashboardContainer extends PureComponent {
    render() {
        const { ...props } = this.props;
        return <DashboardPage {...props} />;
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {       
        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(DashboardContainer));
