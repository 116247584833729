import React, { Component } from "react";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";

import { activateInputs } from "../../utils/formUtils";

import Loader from "../ui/components/Loader";
import NoData from "../ui/components/NoData";

const initialState = {
    passwordCurrent: "",
    passwordNew: "",
    passwordRepeat: "",
};

export default class SecuritySettings extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();

        this.state = initialState;
    }

    componentDidMount() {
        activateInputs();

        const { getSessions } = this.props;
        getSessions();
    }

    componentDidUpdate() {
        activateInputs();
    }

    updatePassword = (e) => {
        e.preventDefault();
        const { updatePassword } = this.props;
        const { passwordCurrent, passwordNew, passwordRepeat } = this.state;

        e.preventDefault();
        if (this.validator.allValid()) {
            updatePassword(passwordCurrent, passwordNew, passwordRepeat).then(() => {
                this.setState(initialState);
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    render() {
        const { t, error, sessions, isFetchingUser, isFetchingSessions, session, logoutAllOthers } = this.props;
        const { passwordCurrent, passwordNew, passwordRepeat } = this.state;

        return (
            <div>
                <div className="input-group highlight-box">
                    <h2 className="no-margin-bottom">{t("settings.security.sessions.header")}</h2>
                    <h3 className="no-margin-top">{t("settings.security.sessions.description")}</h3>
                    {isFetchingSessions ? (
                        <Loader />
                    ) : sessions && sessions.length > 0 ? (
                        <div className="sessions">
                            {sessions.map((sessionRow) => (
                                <div className="session" key={sessionRow.id}>
                                    <h2>{sessionRow.device} {sessionRow.id === session.id ? t("settings.security.sessions.this") : null}</h2>
                                    <div className="flex-container">
                                        <h3>{moment(sessionRow.startedAt).fromNow()}</h3>
                                        <h3>{sessionRow.ip}</h3>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <NoData>{t("settings.security.sessions.notFound")}</NoData>
                    )}

                    {sessions && sessions.length > 0 ? (
                        <div className="input-group">
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    logoutAllOthers();
                                }}
                            >
                                {t("settings.security.sessions.logoutAllOthers")}
                            </button>
                        </div>
                    ) : (null)}
                </div>
                <div className="input-group highlight-box">
                    <h2 className="no-margin-bottom">{t("settings.security.updatePassword.header")}</h2>
                    <h3 className="no-margin-top">{t("settings.security.updatePassword.description")}</h3>
                    {isFetchingUser ? (
                        <Loader />
                    ) : (
                        <form className="no-padding" onSubmit={(e) => this.updatePassword(e)}>
                            <div className="input-container">
                                <div className="input-group">
                                    <div className="input-group no-margin-top">
                                        <input
                                            type="password"
                                            autocomplete="current-password"
                                            value={passwordCurrent}
                                            onChange={(e) => {
                                                e.preventDefault();

                                                this.setState({
                                                    passwordCurrent: e.target.value,
                                                });
                                            }}
                                        />
                                        <label>{t("form.label.passwordCurrent")}</label>
                                    </div>
                                    {this.validator.message(
                                        t("form.label.passwordCurrent"),
                                        passwordCurrent,
                                        "required"
                                    )}
                                </div>
                                <div className="input-group more">
                                    <div className="input-group no-margin-top">
                                        <input
                                            type="password"
                                            autocomplete="new-password"
                                            value={passwordNew}
                                            onChange={(e) => {
                                                e.preventDefault();

                                                this.setState({
                                                    passwordNew: e.target.value,
                                                });
                                            }}
                                        />
                                        <label>{t("form.label.passwordNew")}</label>
                                    </div>
                                    {this.validator.message(
                                        t("form.label.passwordNew"),
                                        passwordNew,
                                        "required|min:6"
                                    )}
                                </div>
                                <div className="input-group">
                                    <div className="input-group no-margin-top">
                                        <input
                                            type="password"
                                            autocomplete="new-password"
                                            value={passwordRepeat}
                                            onChange={(e) => {
                                                e.preventDefault();

                                                this.setState({
                                                    passwordRepeat: e.target.value,
                                                });
                                            }}
                                        />
                                        <label>{t("form.label.passwordRepeat")}</label>
                                    </div>

                                    {this.validator.message(
                                        t("form.label.passwordRepeat"),
                                        passwordRepeat,
                                        "required"
                                    )}
                                </div>
                                <div className="input-group right more">
                                <div className="msg bottom">{error}</div>
                                    <input type="submit" value={t("form.update")} />
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        );
    }
}
