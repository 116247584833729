import React, { Component } from "react";
import moment from "moment";

import Loader from "../ui/components/Loader";

export default class NotificationsPage extends Component {
    
    componentDidMount() {
        const { fetchNotifications } = this.props;
        fetchNotifications();
    }

    componentDidUpdate() {
        const { readNotifications } = this.props;
        readNotifications();
    }

    render() {
        const { t, isFetching, notifications, onNavigateTo } = this.props;

        if (isFetching && notifications.length < 1) {
            return (
                <div className="wrapper top">
                    <Loader />
                </div>
            );
        }

        if (notifications.length > 0) {
            return (
                <div className="wrapper top">
                    <div className="container">
                        <h1 className="no-margin-top">{t("notifications.header")}</h1>
                        <div className="notification-list">
                            {notifications.map((notification) => (
                                <div
                                    className="notification"
                                    onClick={(e) => onNavigateTo(notification.link)}
                                    key={notification.id}
                                >
                                    <img
                                        src={
                                            notification.image
                                                ? notification.image.url
                                                : "/images/transportial-background.png"
                                        }
                                        alt=""
                                    />
                                    <div className="content">
                                        <div className="title">
                                            {notification.title}
                                            <span className="date">
                                                {moment(notification.date).fromNow()}
                                            </span>
                                        </div>
                                        <div className="message">{notification.message}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="wrapper top">
                <h1>{t("notifications.header")}</h1>
                <div className="nothing">{t("notifications.notfound")}</div>
            </div>
        );
    }
}
