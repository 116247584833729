import React, { PureComponent } from 'react';
import moment from 'moment';

import UpdateBrandContainer from '@containers/brand/crud/UpdateBrandContainer';
import CreateBrandContainer from '@containers/brand/crud/CreateBrandContainer';
import BrandContainer from '@containers/brand/BrandContainer';

import TableView from '@uiviews/TableView';
import PopOver from '@uicomponents/PopOver';

export default class BrandsView extends PureComponent {
    state = {
        selectedOptions: ['name', 'updatedAt'],
        popOvers: [],
        filters: null,
    };

    componentDidMount() {
        const { filters, getBrands } = this.props;
        filters ? searchBrands(filters, 0, 20) : getBrands(0, 30);
    }

    render() {
        const {
            t,
            brands,
            isFetchingBrands,
            brandsFrom,
            addToStack,
            deleteBrand,
            searchBrands,
            getBrands,
            brandsLoaded,
            brandsTotalResults,
        } = this.props;
        const { selectedOptions, popOvers, filters } = this.state;

        return (
            <div className="wrapper top">
                {popOvers.map((popOver) => popOver)}
                <div className="container">
                    <TableView
                        {...this.props}
                        downloadable
                        isLoading={isFetchingBrands}
                        isLoadingFrom={brandsFrom}
                        values={brands}
                        title={t('brands.header')}
                        searchEnabled
                        searchPlaceholder={t('search.placeholder')}
                        onFiltersChange={(filters) => {
                            filters ? searchBrands(filters, 0, 20) : getBrands(0, 30);

                            this.setState({
                                filters: filters,
                            });
                        }}
                        onScrolledToBottom={() => {
                            if (brandsTotalResults > brandsLoaded) {
                                filters ? searchBrands(filters, brandsLoaded, 20) : getBrands(brandsLoaded, 20);
                            }
                        }}
                        onCreate={(e) => {
                            addToStack({
                                name: t('brand.create'),
                                component: <CreateBrandContainer />,
                            });
                        }}
                        createNewLabel={t('brand.create')}
                        rowOnClick={(e, brand) => {
                            e.preventDefault();
                            addToStack({
                                name: brand,
                                component: <BrandContainer brand={brand} />,
                            });
                        }}
                        options={[
                            {
                                name: 'name',
                                size: 'one',
                                header: t('brand.name'),
                                type: 'string',
                                selector: 'name',
                                defaultValue: t('noValue'),
                            },
                            {
                                name: 'createdAt',
                                size: 'one',
                                header: t('createdAt'),
                                type: 'string',
                                selector: (value) => moment(value.createdAt).format('DD/MM/YYYY HH:mm'),
                                defaultValue: t('noValue'),
                            },
                            {
                                name: 'updatedAt',
                                size: 'one',
                                header: t('updatedAt'),
                                type: 'string',
                                selector: (value) => moment(value.updatedAt).format('DD/MM/YYYY HH:mm'),
                                defaultValue: t('noValue'),
                            },
                        ]}
                        optionsSelectable
                        selectedOptions={selectedOptions}
                        actions={[
                            {
                                name: t('form.edit'),
                                enabled: true,
                                action: (e, brand) => {
                                    e.preventDefault();
                                    addToStack({
                                        name: t('brand.update'),
                                        component: <UpdateBrandContainer brand={brand} />,
                                    });
                                },
                            },
                            {
                                name: t('form.delete'),
                                enabled: true,
                                action: (e, brand) => {
                                    e.preventDefault();

                                    this.setState({
                                        popOvers: [
                                            ...popOvers,
                                            <PopOver
                                                isActive={true}
                                                className="select-pop-over"
                                                onClose={() => {
                                                    this.setState({
                                                        popOvers: [],
                                                    });
                                                }}
                                            >
                                                <div className="box">
                                                    <div className="title">{t('brand.delete.header')}</div>
                                                    <div className="text">{t('brand.delete.description')}</div>

                                                    <div className="choice">
                                                        <button
                                                            href=""
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                this.setState({
                                                                    popOvers: [],
                                                                });
                                                            }}
                                                        >
                                                            {t('cancel')}
                                                        </button>
                                                        <div
                                                            className="as-link"
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                this.setState({
                                                                    popOvers: [],
                                                                });
                                                                deleteBrand(brand);
                                                            }}
                                                        >
                                                            {t('delete')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </PopOver>,
                                        ],
                                    });
                                },
                            },
                        ]}
                        noDataLabel={t('brands.notFound')}
                    />
                </div>
            </div>
        );
    }
}
