
import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';

import Loader from '@uicomponents/Loader';

import { activateInputs } from '@utils/formUtils';

export default class BusinessForm extends Component {

    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit, formBusiness } = this.props;

        onSubmit && onSubmit(formBusiness);
    }

    render() {
        const { onChange, t, formBusiness } = this.props;

        return (
            <>
                <div className="step-form " key={`${formBusiness.id}${formBusiness.nonce}`}>
                    <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
                        <div className="input-container">
                            <div className="input-group">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        value={formBusiness.name}
                                        onChange={(event) => {
                                            const newBusiness = { ...formBusiness };
                                            newBusiness.name = event.target.value;

                                            onChange('formBusiness', newBusiness, event);
                                        }}
                                    />
                                    <label>{t('form.label.name')}*</label>
                                </div>
                                {this.validator.message(t('form.label.name'), formBusiness.name, 'required')}
                            </div>
                            <div className="input-group more right">
                                <input type="submit" disabled={!this.validator.allValid()} value={t('form.save')} />
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}
    