
export const itemConstants = {
    ITEM_CHANGE_VALUE: "ITEM_CHANGE_VALUE",

    GET_ITEM_STARTED: "GET_ITEM_STARTED",
    GET_ITEM_SUCCESS: "GET_ITEM_SUCCESS",
    GET_ITEM_FAILURE: "GET_ITEM_FAILURE",

    GET_ITEMS_STARTED: "GET_ITEMS_STARTED",
    GET_ITEMS_SUCCESS: "GET_ITEMS_SUCCESS",
    GET_ITEMS_FAILURE: "GET_ITEMS_FAILURE",

    SEARCH_ITEMS_STARTED: "SEARCH_ITEMS_STARTED",
    SEARCH_ITEMS_SUCCESS: "SEARCH_ITEMS_SUCCESS",
    SEARCH_ITEMS_FAILURE: "SEARCH_ITEMS_FAILURE",

    CREATING_ITEM_STARTED: "CREATING_ITEM_STARTED",
    CREATING_ITEM_SUCCESS: "CREATING_ITEM_SUCCESS",
    CREATING_ITEM_FAILURE: "CREATING_ITEM_FAILURE",

    UPDATING_ITEM_STARTED: "UPDATING_ITEM_STARTED",
    UPDATING_ITEM_SUCCESS: "UPDATING_ITEM_SUCCESS",
    UPDATING_ITEM_FAILURE: "UPDATING_ITEM_FAILURE",

    DELETE_ITEM_STARTED: "DELETE_ITEM_STARTED",
    DELETE_ITEM_SUCCESS: "DELETE_ITEM_SUCCESS",
    DELETE_ITEM_FAILURE: "DELETE_ITEM_FAILURE",
};
