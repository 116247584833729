import { userConstants } from '../constants';
import * as userApi from '@api/userApi';

export const changeValue = (name, value) => ({
    type: userConstants.USER_CHANGE_VALUE,
    name,
    value,
});

/**
 * Fetching user
 */
export const startGetUser = () => ({
    type: userConstants.GET_USER_STARTED,
});

export const getUserSuccess = (result) => ({
    type: userConstants.GET_USER_SUCCESS,
    ...result,
});

export const getUserFailure = (error) => ({
    type: userConstants.GET_USER_FAILURE,
    ...error,
});

export const getUser = (id) => {
    return (dispatch) => {
        dispatch(startGetUser());

        const promise = userApi.get(id);

        promise
            .then((result) => {
                dispatch(getUserSuccess(result));
            })
            .catch((error) => {
                dispatch(getUserFailure(error));
            });

        return promise;
    };
};

/**
 * Creating user
 */
export const startCreateUser = () => ({
    type: userConstants.CREATING_USER_STARTED,
});

export const createUserSuccess = (result) => ({
    type: userConstants.CREATING_USER_SUCCESS,
    ...result,
});

export const createUserFailure = (error) => ({
    type: userConstants.CREATING_USER_FAILURE,
    ...error,
});

export const createUser = (user) => {
    return (dispatch) => {
        dispatch(startCreateUser());

        const promise = userApi.create(user);

        promise
            .then((result) => {
                dispatch(createUserSuccess(result));
            })
            .catch((error) => {
                dispatch(createUserFailure(error));
            });

        return promise;
    };
};

/**
 * Inviting user
 */
export const startInviteUser = () => ({
    type: userConstants.INVITING_USER_STARTED,
});

export const inviteUserSuccess = (result) => ({
    type: userConstants.INVITING_USER_SUCCESS,
    ...result,
});

export const inviteUserFailure = (error) => ({
    type: userConstants.INVITING_USER_FAILURE,
    ...error,
});
export const inviteUser = (user) => {
    return (dispatch) => {
        dispatch(startInviteUser());

        const promise = userApi.invite(user);

        promise
            .then((result) => {
                dispatch(inviteUserSuccess(result));
            })
            .catch((error) => {
                dispatch(inviteUserFailure(error));
            });

        return promise;
    };
};

/**
 * Updating user
 */
export const startUpdateUser = () => ({
    type: userConstants.UPDATING_USER_STARTED,
});

export const updateUserSuccess = (result) => ({
    type: userConstants.UPDATING_USER_SUCCESS,
    ...result,
});

export const updateUserFailure = (error) => ({
    type: userConstants.UPDATING_USER_FAILURE,
    ...error,
});

export const updateUser = (user) => {
    return (dispatch) => {
        dispatch(startUpdateUser());

        const promise = userApi.update(user);

        promise
            .then((result) => {
                dispatch(updateUserSuccess(result));
            })
            .catch((error) => {
                dispatch(updateUserFailure(error));
            });

        return promise;
    };
};

export const block = (user) => {
    return (dispatch) => {
        dispatch(startUpdateUser());

        const promise = userApi.block(user);

        promise
            .then((result) => {
                dispatch(updateUserSuccess(result));
            })
            .catch((error) => {
                dispatch(updateUserFailure(error));
            });

        return promise;
    };
};
export const unblock = (user) => {
    return (dispatch) => {
        dispatch(startUpdateUser());

        const promise = userApi.unblock(user);

        promise
            .then((result) => {
                dispatch(updateUserSuccess(result));
            })
            .catch((error) => {
                dispatch(updateUserFailure(error));
            });

        return promise;
    };
};

/**
 * Deleting user
 */
export const startDeleteUser = () => ({
    type: userConstants.DELETE_USER_STARTED,
});

export const deleteUserSuccess = (result) => ({
    type: userConstants.DELETE_USER_SUCCESS,
    ...result,
});

export const deleteUserFailure = (error) => ({
    type: userConstants.DELETE_USER_FAILURE,
    ...error,
});
export const deleteUser = (user) => {
    return (dispatch) => {
        dispatch(startDeleteUser());

        const promise = userApi.remove(user);

        promise
            .then((result) => {
                dispatch(deleteUserSuccess(result));
            })
            .catch((error) => {
                dispatch(deleteUserFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching users
 */
export const startGetUsers = (from) => ({
    type: userConstants.GET_USERS_STARTED,
    from: from
});

export const getUsersSuccess = (result, from) => ({
    type: userConstants.GET_USERS_SUCCESS,
    from: from,
    ...result,
});

export const getUsersFailure = (error) => ({
    type: userConstants.GET_USERS_FAILURE,
    ...error,
});

export const getUsers = (from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetUsers(from));

        const promise = userApi.latest(from, amount);

        promise
            .then((result) => {
                dispatch(getUsersSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getUsersFailure(error));
            });

        return promise;
    };
};

export const searchUsers = (searchParameters = [], from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetUsers(from));

        const promise = userApi.search(searchParameters, from, amount);

        promise
            .then((result) => {
                dispatch(getUsersSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getUsersFailure(error));
            });

        return promise;
    };
};
