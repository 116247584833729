import React, { Component, Suspense } from 'react';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

import AsideView from '@uicomponents/AsideView';
import Switch from '@uicomponents/Switch';
import Loader from '@uicomponents/Loader';

import { activateInputs } from '@utils/formUtils';

const getComponent = (path) => {
    const Component = React.lazy(() => import(`${path}`));
    return Component;
  };
  
class FilterView extends Component {
    static defaultProps = {
        filters: [],
        onChange: () => null,
    };

    constructor(props) {
        super(props);

        this.state = {
            filters: props.filters,
        };
    }

    onChange = (newFilters) => {
        this.setState({
            filters: newFilters,
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const { onChange } = this.props;
        const { filters } = this.state;

        onChange(filters);
    };

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    render() {
        const { t, popStack } = this.props;
        const { filters } = this.state;


        return (
            <div className="filter-view">
                <AsideView>
                    <h1 className="center">{t('filters.header')}</h1>
                    <div className="step-form">
                        <form
                            className="form active no-padding-top"
                            onSubmit={(e) => {
                                this.onSubmit(e);
                            }}
                        >
                            {filters.map((filter, index) => {
                                const newFilter = { ...filter };
                                const newFilters = [...filters];

                                switch (filter.type) {
                                    case 'query':
                                    case 'string':
                                        return (
                                            <div className="input-group" key={`${index}-${filter.name}`}>
                                                <div className="input-group no-margin-top">
                                                    <input
                                                        type="text"
                                                        value={filter.value}
                                                        onChange={(event) => {
                                                            const query = event.target.value;

                                                            newFilter.value = query;
                                                            if (query == '') {
                                                                newFilters[index] = null;
                                                            } else {
                                                                newFilters[index] = newFilter;
                                                            }

                                                            this.onChange(newFilters);
                                                        }}
                                                    />
                                                    <label>
                                                        {filter.label ? filter.label : t('search.placeholder')}
                                                    </label>
                                                </div>
                                            </div>
                                        );
                                    case 'boolean':
                                        return (
                                            <div className="input-group" key={`${index}-${filter.name}`}>
                                                <div className="input-group no-margin-top">
                                                    <div>{filter.label}</div>
                                                    <Switch
                                                        checked={filter.value ? true : false}
                                                        onChange={(e, newValue) => {
                                                            newFilter.value = newValue ? true : null;
                                                            newFilters[index] = newFilter;

                                                            this.onChange(newFilters);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    case 'list':
                                        return (
                                            <div className="input-group" key={`${index}-${filter.name}`}>
                                                <div className="input-group no-margin-top">
                                                    <Select
                                                        placeholder={filter.label ? filter.label : null}
                                                        value={filter.options.filter((option) => {
                                                            if (filter.value instanceof Array) {
                                                                return filter.value.includes(option.value);
                                                            } else if (filter.value) {
                                                                return option.value === filter.value;
                                                            }
                                                            return false;
                                                        })}
                                                        isMulti={filter.isMulti || false}
                                                        options={filter.options}
                                                        isClearable={true}
                                                        onChange={(event) => {
                                                            if (event instanceof Array) {
                                                                newFilter.value = event.map((event) => event.value);
                                                            } else if (event && event.value) {
                                                                newFilter.value = event.value;
                                                            } else {
                                                                newFilter.value = null;
                                                            }
                                                            newFilters[index] = newFilter;

                                                            this.onChange(newFilters);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    case 'component':
                                        const LoadedComponent = React.lazy(() => import(`./../../${filter.componentPath}`))

                                        return (
                                            <div className="input-group" key={`${index}-${filter.name}`}>
                                                <div className="input-group no-margin-top">
                                                    <Suspense fallback={<></>} >
                                                        <LoadedComponent
                                                            placeholder={filter.label}
                                                            value={filter.value}
                                                            onChange={(event) => {
                                                                if (event instanceof Array) {
                                                                    newFilter.value = event.map((event) => event.value);
                                                                } else if (event && event.value) {
                                                                    newFilter.value = event.value;
                                                                } else {
                                                                    newFilter.value = null;
                                                                }
                                                                newFilters[index] = newFilter;

                                                                this.onChange(newFilters);
                                                            }}
                                                        />
                                                    </Suspense>
                                                </div>
                                            </div>
                                        );
                                }
                            })}
                            <div className="input-group more right">
                                <input type="submit" onClick={(e) => this.onSubmit(e)} value={t('form.apply')} />
                            </div>
                        </form>
                    </div>
                </AsideView>
            </div>
        );
    }
}
export default withTranslation('translation')(FilterView);
