
export const breakdownConstants = {
    BREAKDOWN_CHANGE_VALUE: "BREAKDOWN_CHANGE_VALUE",

    GET_BREAKDOWN_STARTED: "GET_BREAKDOWN_STARTED",
    GET_BREAKDOWN_SUCCESS: "GET_BREAKDOWN_SUCCESS",
    GET_BREAKDOWN_FAILURE: "GET_BREAKDOWN_FAILURE",

    GET_BREAKDOWNS_STARTED: "GET_BREAKDOWNS_STARTED",
    GET_BREAKDOWNS_SUCCESS: "GET_BREAKDOWNS_SUCCESS",
    GET_BREAKDOWNS_FAILURE: "GET_BREAKDOWNS_FAILURE",

    SEARCH_BREAKDOWNS_STARTED: "SEARCH_BREAKDOWNS_STARTED",
    SEARCH_BREAKDOWNS_SUCCESS: "SEARCH_BREAKDOWNS_SUCCESS",
    SEARCH_BREAKDOWNS_FAILURE: "SEARCH_BREAKDOWNS_FAILURE",

    CREATING_BREAKDOWN_STARTED: "CREATING_BREAKDOWN_STARTED",
    CREATING_BREAKDOWN_SUCCESS: "CREATING_BREAKDOWN_SUCCESS",
    CREATING_BREAKDOWN_FAILURE: "CREATING_BREAKDOWN_FAILURE",

    UPDATING_BREAKDOWN_STARTED: "UPDATING_BREAKDOWN_STARTED",
    UPDATING_BREAKDOWN_SUCCESS: "UPDATING_BREAKDOWN_SUCCESS",
    UPDATING_BREAKDOWN_FAILURE: "UPDATING_BREAKDOWN_FAILURE",

    DELETE_BREAKDOWN_STARTED: "DELETE_BREAKDOWN_STARTED",
    DELETE_BREAKDOWN_SUCCESS: "DELETE_BREAKDOWN_SUCCESS",
    DELETE_BREAKDOWN_FAILURE: "DELETE_BREAKDOWN_FAILURE",
};
