
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import CreateBrandPage from "@components/brand/crud/CreateBrandPage.js";

import * as brandActions from "@actions/brandActions";
import * as navigationActions from "@actions/navigationActions";

class CreateBrandContainer extends PureComponent {
    render() {
        const {  changeValue, createBrand, popStack, callback, ...props } = this.props;
        return (
            <CreateBrandPage
                {...props}
                onChange={changeValue}
                createBrand={(brand) =>
                    createBrand( brand).then((response) => {
                        popStack && popStack();
                        callback && callback(response.brand)
                    })
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        ...state.brand,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createBrand: ( brand) => dispatch(brandActions.createBrand( brand)),
        changeValue: (name, value) => dispatch(brandActions.changeValue(name, value)),

        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(CreateBrandContainer));
    