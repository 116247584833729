import { combineReducers } from 'redux';

import auth from './auth';
import user from './user';
import notification from './notification';
import navigation from './navigation';
import settings from './settings';

import business from './business';
import vehicle from './vehicle';
import driver from './driver';
import location from './location';
import ban from './ban';
import maintenance from './maintenance';
import mot from './mot';
import invoice from './invoice';
import reminder from './reminder';
import insurance from './insurance';
import complaint from './complaint';
import breakdown from './breakdown';
import accident from './accident';
import warehouse from './warehouse';
import item from './item';
import tire from './tire';
import certificate from './certificate';
import control from './control';
import brand from './brand';

export default combineReducers({
    auth,
    user,
    notification,
    navigation,
    settings,

    business,
    vehicle,
    driver,
    location,
    ban,
    maintenance,
    mot,
    invoice,
    reminder,
    insurance,
    complaint,
    breakdown,
    accident,
    warehouse,
    item,
    tire,
    certificate,
    reminder,
    control,
    brand
});
