
import { brandConstants } from "@constants/brand.constants";

const initialState = {
    isFetchingBrand: false,
    formBrand: null,
    brand: null,

    isFetchingBrands: false,
    isFetchingBrandsFrom: 0,
    brandsTotalResults: 0,
    brandsLoaded: 0,
    brands: [],
};

export default function brand(state = initialState, action) {
    switch (action.type) {
        case brandConstants.BRAND_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case brandConstants.GET_BRAND_STARTED:
            return { ...state, isFetchingBrand: true };
        case brandConstants.GET_BRAND_FAILURE:
            return { ...state, isFetchingBrand: false, brand: null, error: action.message || "" };
        case brandConstants.GET_BRAND_SUCCESS:
            return {
                ...state,
                isFetchingBrand: false,
                brand: action.brand,
            };
            
        case brandConstants.GET_BRANDS_STARTED:
            return { ...state, isFetchingBrands: true,  isFetchingBrandsFrom: action.from || 0 };
        case brandConstants.GET_BRANDS_FAILURE:
            return { ...state, isFetchingBrands: false, brands: [], error: action.message || "" };
        case brandConstants.GET_BRANDS_SUCCESS:
            return {
                ...state,
                isFetchingBrands: false,
                brands: action.from > 0 ? [...state.brands, ...action.brands] : action.brands,
                brandsLoaded:
                    action.from > 0
                        ? state.brands.length + action.brands.length
                        : action.brands.length,
                brandsTotalResults: action.totalResults || 0,
            };

        case brandConstants.SEARCH_BRANDS_STARTED:
            return { ...state, isFetchingBrands: true, isFetchingBrandsFrom: action.from || 0  };
        case brandConstants.SEARCH_BRANDS_FAILURE:
            return { ...state, isFetchingBrands: false, brands: [], error: action.message || "" };
        case brandConstants.SEARCH_BRANDS_SUCCESS:
            return {
                ...state,
                isFetchingBrands: false,
                brands: action.from > 0 ? [...state.brands, ...action.brands] : action.brands,
                brandsLoaded:
                    action.from > 0
                        ? state.brands.length + action.brands.length
                        : action.brands.length,
                brandsTotalResults: action.totalResults || 0,
            };

        case brandConstants.CREATING_BRAND_STARTED:
            return { ...state, isFetchingBrand: true };
        case brandConstants.CREATING_BRAND_FAILURE:
            return { ...state, isFetchingBrand: false, brand: null, error: action.message || "" };
        case brandConstants.CREATING_BRAND_SUCCESS:
            return {
                ...state,
                isFetchingBrand: false,
                brand: action.brand,
                brands: [action.brand, ...state.brands],
            };

        case brandConstants.UPDATING_BRAND_STARTED:
            return { ...state, isFetchingBrand: true };
        case brandConstants.UPDATING_BRAND_FAILURE:
            return { ...state, isFetchingBrand: false, brand: null, error: action.message || "" };
        case brandConstants.UPDATING_BRAND_SUCCESS:
            return {
                ...state,
                isFetchingBrand: false,
                brand: action.brand,
                brands: [...state.brands].map((brand) => {
                    if (action.brand.id === brand.id) {
                        return action.brand;
                    }
                    return brand;
                }),
            };

        case brandConstants.DELETE_BRAND_STARTED:
            return { ...state, isFetchingBrand: true };
        case brandConstants.DELETE_BRAND_FAILURE:
            return { ...state, isFetchingBrand: false, brand: null, message: action.message || "" };
        case brandConstants.DELETE_BRAND_SUCCESS:
            return {
                ...state,
                isFetchingBrand: false,
                brand: action.brand,
                brands: [...state.brands].filter((brand) => action.brand.id !== brand.id),
            };
        default:
            return state;
    }
}    
    