
export const accidentConstants = {
    ACCIDENT_CHANGE_VALUE: "ACCIDENT_CHANGE_VALUE",

    GET_ACCIDENT_STARTED: "GET_ACCIDENT_STARTED",
    GET_ACCIDENT_SUCCESS: "GET_ACCIDENT_SUCCESS",
    GET_ACCIDENT_FAILURE: "GET_ACCIDENT_FAILURE",

    GET_ACCIDENTS_STARTED: "GET_ACCIDENTS_STARTED",
    GET_ACCIDENTS_SUCCESS: "GET_ACCIDENTS_SUCCESS",
    GET_ACCIDENTS_FAILURE: "GET_ACCIDENTS_FAILURE",

    SEARCH_ACCIDENTS_STARTED: "SEARCH_ACCIDENTS_STARTED",
    SEARCH_ACCIDENTS_SUCCESS: "SEARCH_ACCIDENTS_SUCCESS",
    SEARCH_ACCIDENTS_FAILURE: "SEARCH_ACCIDENTS_FAILURE",

    CREATING_ACCIDENT_STARTED: "CREATING_ACCIDENT_STARTED",
    CREATING_ACCIDENT_SUCCESS: "CREATING_ACCIDENT_SUCCESS",
    CREATING_ACCIDENT_FAILURE: "CREATING_ACCIDENT_FAILURE",

    UPDATING_ACCIDENT_STARTED: "UPDATING_ACCIDENT_STARTED",
    UPDATING_ACCIDENT_SUCCESS: "UPDATING_ACCIDENT_SUCCESS",
    UPDATING_ACCIDENT_FAILURE: "UPDATING_ACCIDENT_FAILURE",

    DELETE_ACCIDENT_STARTED: "DELETE_ACCIDENT_STARTED",
    DELETE_ACCIDENT_SUCCESS: "DELETE_ACCIDENT_SUCCESS",
    DELETE_ACCIDENT_FAILURE: "DELETE_ACCIDENT_FAILURE",
};
