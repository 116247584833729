
export const brandConstants = {
    BRAND_CHANGE_VALUE: "BRAND_CHANGE_VALUE",

    GET_BRAND_STARTED: "GET_BRAND_STARTED",
    GET_BRAND_SUCCESS: "GET_BRAND_SUCCESS",
    GET_BRAND_FAILURE: "GET_BRAND_FAILURE",

    GET_BRANDS_STARTED: "GET_BRANDS_STARTED",
    GET_BRANDS_SUCCESS: "GET_BRANDS_SUCCESS",
    GET_BRANDS_FAILURE: "GET_BRANDS_FAILURE",

    SEARCH_BRANDS_STARTED: "SEARCH_BRANDS_STARTED",
    SEARCH_BRANDS_SUCCESS: "SEARCH_BRANDS_SUCCESS",
    SEARCH_BRANDS_FAILURE: "SEARCH_BRANDS_FAILURE",

    CREATING_BRAND_STARTED: "CREATING_BRAND_STARTED",
    CREATING_BRAND_SUCCESS: "CREATING_BRAND_SUCCESS",
    CREATING_BRAND_FAILURE: "CREATING_BRAND_FAILURE",

    UPDATING_BRAND_STARTED: "UPDATING_BRAND_STARTED",
    UPDATING_BRAND_SUCCESS: "UPDATING_BRAND_SUCCESS",
    UPDATING_BRAND_FAILURE: "UPDATING_BRAND_FAILURE",

    DELETE_BRAND_STARTED: "DELETE_BRAND_STARTED",
    DELETE_BRAND_SUCCESS: "DELETE_BRAND_SUCCESS",
    DELETE_BRAND_FAILURE: "DELETE_BRAND_FAILURE",
};
