
import React, { Component } from "react";

import Loader from "@uicomponents/Loader";
import AsideView from "@uicomponents/AsideView";

import Brand from "@models/brand/Brand";
import BrandForm from "./BrandForm";

export default class CreateBrandPage extends Component {
    
    componentDidMount() {
        const { onChange, targetName } = this.props;
        const newBrand = new Brand("latLonPointGeoReference");
        newBrand.name = targetName || "";
        onChange("formBrand", newBrand);
    }

    render() {
        const { t, formBrand, isFetchingBrand, createBrand } = this.props;

        if (!formBrand || isFetchingBrand) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("brand.new")}</h1>
                <BrandForm
                    {...this.props}
                    key={formBrand.id}
                    onSubmit={(brand) => createBrand(brand)}
                />
            </AsideView>
        );
    }
}
    