import React, { Component } from 'react';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import { activateInputs } from '../../../utils/formUtils';

export default class CreateUser extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
        this.state = {};
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit, formUser } = this.props;

        onSubmit && onSubmit(formUser);
    }

    handleInvite(e) {
        e.preventDefault();
        const { onInvite, formUser } = this.props;

        onInvite && onInvite(formUser);
    }

    render() {
        const { onChange, t, formUser } = this.props;
        return (
            <div className="step-form" key={formUser.id}>
                <form className="form active" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-container">
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formUser.firstName}
                                    onChange={(event) => {
                                        const newUser = { ...formUser };
                                        newUser.firstName = event.target.value;

                                        onChange('formUser', newUser, event);
                                    }}
                                />
                                <label>{t('form.label.firstName')}*</label>
                            </div>
                            {this.validator.message(t('form.label.firstName'), formUser.firstName, 'required')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formUser.lastName}
                                    onChange={(event) => {
                                        const newUser = { ...formUser };
                                        newUser.lastName = event.target.value;

                                        onChange('formUser', newUser, event);
                                    }}
                                />
                                <label>{t('form.label.lastName')}*</label>
                            </div>
                            {this.validator.message(t('form.label.lastName'), formUser.lastName, 'required')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formUser.username}
                                    onChange={(event) => {
                                        const newUser = { ...formUser };
                                        newUser.username = event.target.value;

                                        onChange('formUser', newUser, event);
                                    }}
                                />
                                <label>{t('form.label.username')}*</label>
                            </div>
                            {this.validator.message(t('form.label.username'), formUser.username, 'required')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formUser.email}
                                    onChange={(event) => {
                                        const newUser = { ...formUser };
                                        newUser.email = event.target.value;

                                        onChange('formUser', newUser, event);
                                    }}
                                />
                                <label>{t('form.label.email')}*</label>
                            </div>
                            {this.validator.message(t('form.label.email'), formUser.email, 'required|email')}
                        </div>
                        <div className="input-group no-margin-top ">
                            <div className="input-group more half">
                                <button
                                    type="button"
                                    disabled={!this.validator.allValid()}
                                    onClick={(e) => this.handleInvite(e)}
                                >
                                    {t('form.invite')}
                                </button>
                            </div>
                            <div className="input-group more half right">
                                <input
                                    type="submit"
                                    disabled={!this.validator.allValid()}
                                    onClick={(e) => this.handleSubmit(e)}
                                    value={t('form.save')}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
