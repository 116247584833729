
export const controlConstants = {
    CONTROL_CHANGE_VALUE: "CONTROL_CHANGE_VALUE",

    GET_CONTROL_STARTED: "GET_CONTROL_STARTED",
    GET_CONTROL_SUCCESS: "GET_CONTROL_SUCCESS",
    GET_CONTROL_FAILURE: "GET_CONTROL_FAILURE",

    GET_CONTROLS_STARTED: "GET_CONTROLS_STARTED",
    GET_CONTROLS_SUCCESS: "GET_CONTROLS_SUCCESS",
    GET_CONTROLS_FAILURE: "GET_CONTROLS_FAILURE",

    SEARCH_CONTROLS_STARTED: "SEARCH_CONTROLS_STARTED",
    SEARCH_CONTROLS_SUCCESS: "SEARCH_CONTROLS_SUCCESS",
    SEARCH_CONTROLS_FAILURE: "SEARCH_CONTROLS_FAILURE",

    CREATING_CONTROL_STARTED: "CREATING_CONTROL_STARTED",
    CREATING_CONTROL_SUCCESS: "CREATING_CONTROL_SUCCESS",
    CREATING_CONTROL_FAILURE: "CREATING_CONTROL_FAILURE",

    UPDATING_CONTROL_STARTED: "UPDATING_CONTROL_STARTED",
    UPDATING_CONTROL_SUCCESS: "UPDATING_CONTROL_SUCCESS",
    UPDATING_CONTROL_FAILURE: "UPDATING_CONTROL_FAILURE",

    DELETE_CONTROL_STARTED: "DELETE_CONTROL_STARTED",
    DELETE_CONTROL_SUCCESS: "DELETE_CONTROL_SUCCESS",
    DELETE_CONTROL_FAILURE: "DELETE_CONTROL_FAILURE",
};
