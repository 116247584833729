
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import BusinessView from "@components/business/BusinessView";

import * as businessActions from "@actions/businessActions";
import * as navigationActions from "@actions/navigationActions";

class BusinessContainer extends PureComponent {
    componentDidMount() {
        const { ownProps, changeValue, getBusiness } = this.props;
        if (ownProps.business) {
            changeValue("business", ownProps.business);
        } else if (ownProps.businessId) {
            getBusiness(ownProps.businessId);
        }
    }

    render() {
        const { changeValue, getBusiness, deleteBusiness, ...props } = this.props;
        return (
            <BusinessView
                {...props}
                onChange={changeValue}
                getBusiness={(id = "") => getBusiness(id)}
                deleteBusiness={(business) => deleteBusiness(business)}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.auth.user,
        ...state.business,
        
        ownProps: ownProps,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (name, value) => dispatch(businessActions.changeValue(name, value)),

        getBusiness: (id) => dispatch(businessActions.getBusiness(id)),
        deleteBusiness: (business) => dispatch(businessActions.deleteBusiness(business)),

        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translation")(BusinessContainer));
    
    