import React, { PureComponent } from "react";

import CloseButton from "./CloseButton";
import BreadCrumbs from "./BreadCrumbs";
import PopOver from "./PopOver";

export default class ComponentStack extends PureComponent {
    state = {
        animationHasEnded: false,
    };
    addExtraProps(Component, extraProps) {
        return Component ? <Component.type {...Component.props} {...extraProps} /> : null;
    }
    render() {
        const { components, popStack } = this.props;

        const onClose = () => {
            popStack && popStack();
        };
        const componentsLength = components.length;

        return (
            <PopOver isActive={components && componentsLength > 0} onClose={() => onClose()}>
                <div className="component-stack">
                    {components.map((component, index) => {
                        return (
                            <div
                                className={`rightview scrollable thick ${
                                    component.animateOut ? "animateOut" : "animateIn"
                                } ${componentsLength - 1 === index ? "" : " below"} ${
                                    component.fullWidth ? " full-width" : ""
                                }`}
                                key={`component-stack-${index}`}
                                style={{
                                    zIndex: 11 + componentsLength,
                                }}
                                onClick={(e) => {
                                    if (componentsLength - 1 !== index) {
                                        onClose();
                                    }
                                }}
                                onAnimationEnd={() => {
                                    this.setState({ animationHasEnded: true });
                                }}
                            >
                                <div className={`pop-up-container ${component.className ? component.className : ''}`}>
                                    <div className="actions">
                                        <BreadCrumbs crumbs={[]} />
                                        <CloseButton onClick={() => onClose()} />
                                    </div>
                                    {component
                                        ? this.addExtraProps(component.component, { ...this.state })
                                        : null}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </PopOver>
        );
    }
}
