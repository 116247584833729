
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import BusinessesView from "@components/business/BusinessesView";

import * as businessActions from "@actions/businessActions";
import * as navigationActions from "@actions/navigationActions";

class BusinessesContainer extends PureComponent {
    render() {
        const {  getBusinesses, searchBusinesses, deleteBusiness, ...props } = this.props;
        return (
            <BusinessesView
                {...props}
                getBusinesses={(from = 0, to = 20) => getBusinesses( from, to)}
                searchBusinesses={(searchParameters, from, to) =>
                    searchBusinesses( searchParameters, from, to)
                }

                deleteBusiness={(business) => deleteBusiness( business)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        ...state.business,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBusinesses: ( from, to) => dispatch(businessActions.getBusinesses( from, to)),
        searchBusinesses: ( searchParameters, from, to) =>
            dispatch(businessActions.searchBusinesses( searchParameters, from, to)),
        deleteBusiness: ( business) => dispatch(businessActions.deleteBusiness( business)),
        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation("translation")(BusinessesContainer));
    
    