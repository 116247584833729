import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Loader from '../ui/components/Loader';

export default class HeaderNotificationView extends Component {
    onScroll(e) {
        const { getNotifications, notificationsLoaded, notificationsTotalResults } = this.props;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom && notificationsLoaded < notificationsTotalResults) {
            getNotifications(notificationsLoaded, notificationsLoaded + 20);
        }
    }

    render() {
        const { t, notificationsIsFetching, notifications, goToPage, } = this.props;

        if (!notificationsIsFetching) {
            if (notifications.length > 0) {
                return (
                    <div>
                        <div className="item-list scrollable" onScroll={(e) => this.onScroll(e)}>
                            {notifications.map((notification, index) => (
                                <Link to={notification.link} key={index}>
                                    <div className="message">
                                        <img
                                            src={
                                                notification.image
                                                    ? notification.image.url
                                                    : '/images/transportial-background.png'
                                            }
                                            alt=""
                                        />
                                        <div className="left">
                                            <h1>
                                                {notification.title}
                                                <span className="date">{moment(notification.date).fromNow()}</span>
                                            </h1>
                                            <p>{notification.message}</p>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                        <div className="more">
                            <Link to="/" onClick={goToPage}>
                                {t('notifications.all')}
                            </Link>
                        </div>
                    </div>
                );
            }
            return <div className="nothing">{t('notifications.notFound')}</div>;
        }

        return <Loader />;
    }
}
