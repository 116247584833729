import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import LoginPage from '../../components/authentication/LoginPage';
import * as authActions from '../../state/actions/authActions.js';

class LoginContainer extends Component {
    render() {
        const { changeValue, login, ...props } = this.props;

        const { t, username, password, history } = props;

        return (
            <LoginPage
                {...props}
                onChange={changeValue}
                onLogin={() => {
                    login(username, password, t)
                        .then((response) => {
                            history.push('/dashboard');
                        })
                        .catch((error) => console.log(error));
                }}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        error: state.auth.error,
        username: state.auth.username,
        email: state.auth.email,
        password: state.auth.password,
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeValue: (name, value) => dispatch(authActions.changeLoginValue(name, value)),
        login: (username, password, t) => dispatch(authActions.login(username, password, t)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(LoginContainer));
