
import api from "../api";


// CRUD
export const get = (id = '') => {
    return api.get(`/business/${id}`);
};

export const create = (business) => {
    return api.post(`/business`, business);
};

export const update = (business) => {
    return api.put(`/business`, business);
};

export const remove = (business) => {
    return api.delete(`/business/${business.id}`);
};


// Fetch and Search
export const latest = (offset = 0, amount = 20) => {
    return api.get(`/businesses/${offset}/${amount}`);
};

export const search = (searchParameters = [], offset = 0, amount = 20) => {
    return api.post(`/businesses/${offset}/${amount}`, searchParameters);
};
