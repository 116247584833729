import React, { PureComponent } from 'react';
import moment from 'moment';

import UpdateBusinessContainer from '@containers/business/crud/UpdateBusinessContainer';
import CreateBusinessContainer from '@containers/business/crud/CreateBusinessContainer';
import BusinessContainer from '@containers/business/BusinessContainer';

import TableView from '@uiviews/TableView';
import PopOver from '@uicomponents/PopOver';

export default class BusinessesView extends PureComponent {
    state = {
        selectedOptions: ['name', 'updatedAt'],
        popOvers: [],
        filters: null,
    };

    componentDidMount() {
        const { filters, getBusinesses } = this.props;
        filters ? searchBusinesses(filters, 0, 20) : getBusinesses(0, 30);
    }

    render() {
        const {
            t,
            businesses,
            isFetchingBusinesses,
            businessesFrom,
            addToStack,
            deleteBusiness,
            searchBusinesses,
            getBusinesses,
            businessesLoaded,
            businessesTotalResults,
        } = this.props;
        const { selectedOptions, popOvers, filters } = this.state;

        return (
            <div className="wrapper top">
                {popOvers.map((popOver) => popOver)}
                <div className="container">
                    <TableView
                        {...this.props}
                        downloadable
                        isLoading={isFetchingBusinesses}
                        isLoadingFrom={businessesFrom}
                        values={businesses}
                        title={t('businesses.header')}
                        subTitle={
                            isFetchingBusinesses
                                ? null
                                : `${businessesLoaded} ${t('of')} ${businessesTotalResults} ${t('businesses')}`
                        }
                        searchEnabled
                        searchPlaceholder={t('search.placeholder')}
                        onFiltersChange={(filters) => {
                            filters ? searchBusinesses(filters, 0, 20) : getBusinesses(0, 30);

                            this.setState({
                                filters: filters,
                            });
                        }}
                        onScrolledToBottom={() => {
                            if (businessesTotalResults > businessesLoaded) {
                                filters
                                    ? searchBusinesses(filters, businessesLoaded, 20)
                                    : getBusinesses(businessesLoaded, 20);
                            }
                        }}
                        onCreate={(e) => {
                            addToStack({
                                name: t('business.create'),
                                component: <CreateBusinessContainer />,
                            });
                        }}
                        createNewLabel={t('business.create')}
                        rowOnClick={(e, business) => {
                            e.preventDefault();
                            addToStack({
                                name: business,
                                component: <BusinessContainer business={business} />,
                            });
                        }}
                        options={[
                            {
                                name: 'name',
                                size: 'one',
                                header: t('business.name'),
                                type: 'string',
                                selector: 'name',
                                defaultValue: t('noValue'),
                            },
                            {
                                name: 'createdAt',
                                size: 'one',
                                header: t('createdAt'),
                                type: 'string',
                                selector: (value) => moment(value.createdAt).format('DD/MM/YYYY HH:mm'),
                                defaultValue: t('noValue'),
                            },
                            {
                                name: 'updatedAt',
                                size: 'one',
                                header: t('updatedAt'),
                                type: 'string',
                                selector: (value) => moment(value.updatedAt).format('DD/MM/YYYY HH:mm'),
                                defaultValue: t('noValue'),
                            },
                        ]}
                        optionsSelectable
                        selectedOptions={selectedOptions}
                        actions={[
                            {
                                name: t('form.edit'),
                                enabled: true,
                                action: (e, business) => {
                                    e.preventDefault();
                                    addToStack({
                                        name: t('business.update'),
                                        component: <UpdateBusinessContainer business={business} />,
                                    });
                                },
                            },
                            {
                                name: t('form.delete'),
                                enabled: true,
                                action: (e, business) => {
                                    e.preventDefault();

                                    this.setState({
                                        popOvers: [
                                            ...popOvers,
                                            <PopOver
                                                isActive={true}
                                                className="select-pop-over"
                                                onClose={() => {
                                                    this.setState({
                                                        popOvers: [],
                                                    });
                                                }}
                                            >
                                                <div className="box">
                                                    <div className="title">{t('business.delete.header')}</div>
                                                    <div className="text">{t('business.delete.description')}</div>

                                                    <div className="choice">
                                                        <button
                                                            href=""
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                this.setState({
                                                                    popOvers: [],
                                                                });
                                                            }}
                                                        >
                                                            {t('cancel')}
                                                        </button>
                                                        <div
                                                            className="as-link"
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                this.setState({
                                                                    popOvers: [],
                                                                });
                                                                deleteBusiness(business);
                                                            }}
                                                        >
                                                            {t('delete')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </PopOver>,
                                        ],
                                    });
                                },
                            },
                        ]}
                        noDataLabel={t('businesses.notFound')}
                    />
                </div>
            </div>
        );
    }
}
