import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import UsersView from '@components/user/UsersView';

import * as userActions from '@actions/userActions';
import * as navigationActions from '@actions/navigationActions';

class UsersContainer extends Component {
    render() {
        const { deleteUser, getUsers, searchUsers, ...props } = this.props;

        return (
            <UsersView
                {...props}
                deleteUser={(user) => deleteUser(user)}
                getUsers={(from = 0, to = 20) => getUsers(from, to)}
                searchUsers={(searchParameters, from, to) => searchUsers(searchParameters, from, to)}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.user,
        ...state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteUser: (user) => dispatch(userActions.deleteUser(user)),
        getUsers: (from, to) => dispatch(userActions.getUsers(from, to)),
        searchUsers: (searchParameters, from, to) => dispatch(userActions.searchUsers(searchParameters, from, to)),

        block: (user) => dispatch(userActions.block(user)),
        unblock: (user) => dispatch(userActions.unblock(user)),

        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(UsersContainer));
