import React, { Component } from 'react';

import { activateInputs } from '../../utils/formUtils';

import Switch from '../ui/components/Switch';

export default class PrivacySettings extends Component {
    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    updatePrivacySetting = (newPrivacy) => {
        const { settings, onChange, updateSettings } = this.props;
        const newSettings = { ...settings };
        newSettings.privacy = newPrivacy;
        onChange('settings', newSettings);
        updateSettings(newSettings);
    };

    render() {
        const { t, settings } = this.props;
        const { privacy } = settings;
        const { openToThePublic } = privacy;

        return (
            <div>
                <div className="input-group highlight-box">
                    <h2 className="no-margin-bottom">{t(`settings.privacy.openProfile.header`)}</h2>
                    <h3 className="no-margin-top">{t(`settings.privacy.openProfile.description`)}</h3>
                    <Switch
                        checked={openToThePublic}
                        onChange={(e, newState) => {
                            const newPrivacy = { ...privacy };
                            newPrivacy.openToThePublic = newState;

                            this.updatePrivacySetting(newPrivacy);
                        }}
                    />
                </div>
            </div>
        );
    }
}
