import { navigationConstants } from '../constants';

const initialState = {
    components: [],
    componentsPreviousSize: 0,

    alerts: [],
    alertsPreviousSize: 0,
};

export default function navigation(state = initialState, action) {
    switch (action.type) {
        case navigationConstants.ADD_TO_STACK:
            return {
                ...state,
                components: [...state.components, action.component],
                componentsPreviousSize: 0,
            };
        case navigationConstants.ANIMATE_LAST_OUT:
            return {
                ...state,
                components: [...state.components].map((component, index) =>
                    index === state.components.length - 1 ? { ...component, animateOut: true } : component
                ),
            };
        case navigationConstants.UPDATE_STACK_ELEMENT:
            return {
                ...state,
                components: [...state.components].map((component) =>
                    action.component.name === component.name ? action.component : component
                ),
            };
        case navigationConstants.POP_STACK:
            const newComponents = [...state.components];
            newComponents.pop();
            return {
                ...state,
                componentsPreviousSize: state.components.length,
                components: newComponents,
            };
        case navigationConstants.CLEAR_STACK:
            return {
                ...state,
                componentsPreviousSize: state.components.length,
                components: [],
            };
        case navigationConstants.CREATE_ALERT:
            return {
                ...state,
                alerts: [...state.alerts, action.alert],
                alertsPreviousSize: 0,
            };
        case navigationConstants.REMOVE_ALERT:
            return {
                ...state,
                alerts: [...state.alerts].filter((alert) => action.alert.id !== alert.id),
            };
        case navigationConstants.ANIMATE_ALERT_OUT:
            return {
                ...state,
                alerts: [...state.alerts].map((alert) => (action.alert.id === alert.id ? action.alert : alert)),
                alertsPreviousSize: state.alerts.length,
            };
        case navigationConstants.POP_ALERT:
            const newAlerts = [...state.alerts];
            newAlerts.pop();
            return {
                ...state,
                alerts: newAlerts,
                alertsPreviousSize: state.alerts.length,
            };
        default:
            return state;
    }
}
