import React, { Component } from 'react';
import { matchPath } from 'react-router';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createGlobalStyle } from 'styled-components';

import { i18n } from './index';

import Loader from './components/ui/components/Loader';

import ComponentStackContainer from './containers/ComponentStackContainer';
import AlertStackContainer from './containers/AlertStackContainer';

/* Components */
import Header from './containers/header/HeaderContainer';

import Login from './containers/authentication/LoginContainer';
import Register from './containers/authentication/RegisterContainer';
import Forgot from './containers/authentication/ForgotContainer';
import Recover from './containers/authentication/ForgotResetContainer';
import Logout from './containers/authentication/LogoutContainer';
import Dashboard from './containers/dashboard/DashboardContainer';
import Settings from './containers/settings/SettingsContainer';

import UsersContainer from './containers/user/UsersContainer';
import BusinessesContainer from './containers/business/BusinessesContainer';
import BrandsContainer from './containers/brand/BrandsContainer';

import NotFound from '@components/defaults/404';

import * as navigationActions from './state/actions/navigationActions';

class IndexContainer extends Component {
    render() {
        const PageShell = (Page) => {
            return (props) => (
                <div className={`page${props.noHeader ? ' clean' : ''}`}>
                    <Header {...props} />
                    <Page {...props} />
                </div>
            );
        };

        const AlwaysAccessibleRoute = ({ component: Component, ...rest }) => (
            <Route {...rest} render={(props) => <Component {...props} {...rest} />} />
        );

        const DefaultRoute = ({ component: Component, ...rest }) => (
            <Route
                {...rest}
                render={(props) =>
                    this.props.isAuthenticated ? <Redirect to="/dashboard" /> : <Component {...props} {...rest} />
                }
            />
        );

        const PortalRoute = ({ component: Component, ...rest }) => {
            return (
                <Route
                    {...rest}
                    render={(props) => {
                        return this.props.isAuthenticated ? <Component {...props} {...rest} /> : <Redirect to="/login" />;
                    }}
                />
            );
        };

        return (
            <Route
                render={({ location }) => (
                    <div className="background">
                        <AlertStackContainer />
                        <ComponentStackContainer />
                        <Switch location={location}>
                            {/* Portal login */}
                            <DefaultRoute exact path="/" noHeader noFooter component={PageShell(Login)} />
                            <DefaultRoute exact path="/login" noHeader noFooter component={PageShell(Login)} />
                            <DefaultRoute exact path="/register" noHeader noFooter component={PageShell(Register)} />
                            <DefaultRoute exact path="/forgot" component={PageShell(Forgot)} noHeader noFooter />
                            <DefaultRoute
                                exact
                                path="/user/recover/:user/:token"
                                component={PageShell(Recover)}
                                noHeader
                                noFooter
                            />
                            <AlwaysAccessibleRoute
                                exact
                                path="/logout"
                                component={PageShell(Logout)}
                                noFooter
                                noHeader
                            />

                            {/* Portal */}
                            <PortalRoute exact path="/dashboard" component={PageShell(Dashboard)} />

                            <PortalRoute exact path="/companies" component={PageShell(BusinessesContainer)} />
                            <PortalRoute exact path="/users" component={PageShell(UsersContainer)} />
                            <PortalRoute exact path="/brands" component={PageShell(BrandsContainer)} />

                            <PortalRoute exact path="/settings" component={PageShell(Settings)} />
                            <PortalRoute exact path="/settings/:tab" component={PageShell(Settings)} />

                            {/* Fallbacks */}
                            <AlwaysAccessibleRoute path="*" component={PageShell(NotFound)} />
                        </Switch>
                    </div>
                )}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
        ...ownProps,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        popStack: () => dispatch(navigationActions.popStack()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(IndexContainer));
