import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Header from '@components/header/Header.js';

import * as notificationActions from '@actions/notificationActions';
import * as navigationActions from '@actions/navigationActions';

class HeaderContainer extends Component {
    render() {
        const {  getNotifications, readNotifications, ...props } = this.props;
        const { user, notifications } = this.props;

        return (
            <Header
                {...props}
                getNotifications={(from = 0, amount = 20) => getNotifications( from, amount)}
                readNotifications={() => {
                    const unreadNotifications = [...notifications].filter((notification) => notification.read.filter((r) => r.by.id === user.id).length < 1).map(notification => notification.id);
                    if (unreadNotifications.length > 0) readNotifications( unreadNotifications);
                }}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,

        notificationsIsFetching: state.notification.notificationsIsFetching,
        notifications: state.notification.notifications,
        notificationsTotalResults: state.notification.notificationsTotalResults,
        notificationsUnread: state.notification.notificationsUnread,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        getNotifications: ( from, to) => dispatch(notificationActions.getNotifications( from, to)),
        readNotifications: ( notifications) => dispatch(notificationActions.readNotifications( notifications)),

        addToStack: (component) => dispatch(navigationActions.addToStack(component)),
        removeFromStack: (component) => dispatch(navigationActions.removeFromStack(component)),
        popStack: () => dispatch(navigationActions.popStack()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(HeaderContainer));
