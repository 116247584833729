import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import NotificationsPage from '../../components/notification/NotificationsPage';
import * as notificationActions from '../../state/actions/notificationActions';

class NotificationContainer extends Component {
    render() {
        const {  getNotifications, readNotifications, ...props } = this.props;

        const { user, notifications } = this.props;
        
        return (
            <NotificationsPage
                {...props}
                fetchNotifications={(from = 0, to = 20) => getNotifications( from, to)}
                readNotifications={() => {
                    const unreadNotifications = notifications.filter((notification) => notification.read.filter((r) => r.by.id === user.id).length < 1).map(notification => notification.id);
                    if (unreadNotifications.length > 0) readNotifications( unreadNotifications);
                }}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.auth.user,
        ...state.notification,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getNotifications: ( from, to) => dispatch(notificationActions.getNotifications( from, to)),
        readNotifications: ( notifications) => dispatch(notificationActions.readNotifications( notifications)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation')(NotificationContainer));
