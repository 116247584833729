
import { warehouseConstants } from "@constants/warehouse.constants";

const initialState = {
    isFetchingWarehouse: false,
    formWarehouse: null,
    warehouse: null,

    isFetchingWarehouses: false,
    isFetchingWarehousesFrom: 0,
    warehousesTotalResults: 0,
    warehousesLoaded: 0,
    warehouses: [],
};

export default function warehouse(state = initialState, action) {
    switch (action.type) {
        case warehouseConstants.WAREHOUSE_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case warehouseConstants.GET_WAREHOUSE_STARTED:
            return { ...state, isFetchingWarehouse: true };
        case warehouseConstants.GET_WAREHOUSE_FAILURE:
            return { ...state, isFetchingWarehouse: false, warehouse: null, error: action.message || "" };
        case warehouseConstants.GET_WAREHOUSE_SUCCESS:
            return {
                ...state,
                isFetchingWarehouse: false,
                warehouse: action.warehouse,
            };
            
        case warehouseConstants.GET_WAREHOUSES_STARTED:
            return { ...state, isFetchingWarehouses: true,  isFetchingWarehousesFrom: action.from || 0 };
        case warehouseConstants.GET_WAREHOUSES_FAILURE:
            return { ...state, isFetchingWarehouses: false, warehouses: [], error: action.message || "" };
        case warehouseConstants.GET_WAREHOUSES_SUCCESS:
            return {
                ...state,
                isFetchingWarehouses: false,
                warehouses: action.from > 0 ? [...state.warehouses, ...action.warehouses] : action.warehouses,
                warehousesLoaded:
                    action.from > 0
                        ? state.warehouses.length + action.warehouses.length
                        : action.warehouses.length,
                warehousesTotalResults: action.totalResults || 0,
            };

        case warehouseConstants.SEARCH_WAREHOUSES_STARTED:
            return { ...state, isFetchingWarehouses: true, isFetchingWarehousesFrom: action.from || 0  };
        case warehouseConstants.SEARCH_WAREHOUSES_FAILURE:
            return { ...state, isFetchingWarehouses: false, warehouses: [], error: action.message || "" };
        case warehouseConstants.SEARCH_WAREHOUSES_SUCCESS:
            return {
                ...state,
                isFetchingWarehouses: false,
                warehouses: action.from > 0 ? [...state.warehouses, ...action.warehouses] : action.warehouses,
                warehousesLoaded:
                    action.from > 0
                        ? state.warehouses.length + action.warehouses.length
                        : action.warehouses.length,
                warehousesTotalResults: action.totalResults || 0,
            };

        case warehouseConstants.CREATING_WAREHOUSE_STARTED:
            return { ...state, isFetchingWarehouse: true };
        case warehouseConstants.CREATING_WAREHOUSE_FAILURE:
            return { ...state, isFetchingWarehouse: false, warehouse: null, error: action.message || "" };
        case warehouseConstants.CREATING_WAREHOUSE_SUCCESS:
            return {
                ...state,
                isFetchingWarehouse: false,
                warehouse: action.warehouse,
                warehouses: [action.warehouse, ...state.warehouses],
            };

        case warehouseConstants.UPDATING_WAREHOUSE_STARTED:
            return { ...state, isFetchingWarehouse: true };
        case warehouseConstants.UPDATING_WAREHOUSE_FAILURE:
            return { ...state, isFetchingWarehouse: false, warehouse: null, error: action.message || "" };
        case warehouseConstants.UPDATING_WAREHOUSE_SUCCESS:
            return {
                ...state,
                isFetchingWarehouse: false,
                warehouse: action.warehouse,
                warehouses: [...state.warehouses].map((warehouse) => {
                    if (action.warehouse.id === warehouse.id) {
                        return action.warehouse;
                    }
                    return warehouse;
                }),
            };

        case warehouseConstants.DELETE_WAREHOUSE_STARTED:
            return { ...state, isFetchingWarehouse: true };
        case warehouseConstants.DELETE_WAREHOUSE_FAILURE:
            return { ...state, isFetchingWarehouse: false, warehouse: null, message: action.message || "" };
        case warehouseConstants.DELETE_WAREHOUSE_SUCCESS:
            return {
                ...state,
                isFetchingWarehouse: false,
                warehouse: action.warehouse,
                warehouses: [...state.warehouses].filter((warehouse) => action.warehouse.id !== warehouse.id),
            };
        default:
            return state;
    }
}    
    