import React, { Component } from 'react';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import { activateInputs } from '@utils/formUtils';

export default class BusinessForm extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator();
        this.state = {};
    }

    componentDidMount() {
        activateInputs();
    }

    componentDidUpdate() {
        activateInputs();
    }

    handleSubmit(e) {
        e.preventDefault();
        const { onSubmit, formBusiness } = this.props;

        onSubmit && onSubmit(formBusiness);
    }

    render() {
        const { onChange, t, formBusiness, } = this.props;

        return (
            <div className="step-form" key={formBusiness.id}>
                <form className="form active" onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="input-container">
                        <div className="input-group more">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formBusiness.name}
                                    onChange={(event) => {
                                        const newBusiness = { ...formBusiness };
                                        newBusiness.name = event.target.value;

                                        onChange('formBusiness', newBusiness, event);
                                    }}
                                />
                                <label>{t('form.label.name')}*</label>
                            </div>
                            {this.validator.message(t('form.label.name'), formBusiness.name, 'required')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formBusiness.coc}
                                    onChange={(event) => {
                                        const newBusiness = { ...formBusiness };
                                        newBusiness.coc = event.target.value;

                                        onChange('formBusiness', newBusiness, event);
                                    }}
                                />
                                <label>{t('form.label.coc')}*</label>
                            </div>
                            {this.validator.message(t('form.label.coc'), formBusiness.coc, 'required')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formBusiness.vat}
                                    onChange={(event) => {
                                        const newBusiness = { ...formBusiness };
                                        newBusiness.vat = event.target.value;
                                        onChange('formBusiness', newBusiness, event);
                                    }}
                                />
                                <label>{t('form.label.vat')}</label>
                            </div>
                            {this.validator.message(t('form.label.vat'), formBusiness.vat, 'string')}
                        </div>

                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <textarea
                                    type="text"
                                    value={formBusiness.description}
                                    onChange={(event) => {
                                        const newBusiness = { ...formBusiness };
                                        newBusiness.description = event.target.value;
                                        onChange('formBusiness', newBusiness, event);
                                    }}
                                ></textarea>
                                <label>{t('form.label.description')}</label>
                            </div>
                            {this.validator.message(t('form.label.description'), formBusiness.description, 'string')}
                        </div>
                        <div className="input-group">
                            <div className="input-group no-margin-top">
                                <input
                                    type="text"
                                    value={formBusiness.website}
                                    onChange={(event) => {
                                        const newBusiness = { ...formBusiness };
                                        newBusiness.website = event.target.value;
                                        onChange('formBusiness', newBusiness, event);
                                    }}
                                />
                                <label>{t('form.label.website')}</label>
                            </div>
                            {this.validator.message(t('form.label.website'), formBusiness.website, 'url')}
                        </div>
                        <div className="input-group no-margin-top ">
                            <div className="input-group more right">
                                <input
                                    type="submit"
                                    disabled={!this.validator.allValid()}
                                    onClick={(e) => this.handleSubmit(e)}
                                    value={t('form.save')}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
