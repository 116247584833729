
import { vehicleConstants } from '../constants';
import * as vehicleApi from '@api/vehicleApi';

export const changeValue = (name, value) => ({
    type: vehicleConstants.VEHICLE_CHANGE_VALUE,
    name,
    value,
});

/**
 * Fetching vehicle
 */
export const startGetVehicle = () => ({
    type: vehicleConstants.GET_VEHICLE_STARTED,
});

export const getVehicleSuccess = (result) => ({
    type: vehicleConstants.GET_VEHICLE_SUCCESS,
    vehicle: result,
});

export const getVehicleFailure = (error) => ({
    type: vehicleConstants.GET_VEHICLE_FAILURE,
    ...error,
});

export const getVehicle = (id) => {
    return (dispatch) => {
        dispatch(startGetVehicle());

        const promise = vehicleApi.get(id);

        promise
            .then((result) => {
                dispatch(getVehicleSuccess(result));
            })
            .catch((error) => {
                dispatch(getVehicleFailure(error));
            });

        return promise;
    };
};

/**
 * Creating vehicle
 */
export const changeCreateVehicle = (name, value) => ({
    type: vehicleConstants.CREATING_VEHICLE_CHANGE_VALUE,
    name,
    value,
});
export const startCreateVehicle = () => ({
    type: vehicleConstants.CREATING_VEHICLE_STARTED,
});

export const createVehicleSuccess = (result, vehicle) => ({
    type: vehicleConstants.CREATING_VEHICLE_SUCCESS,
    vehicle, 
    ...result
});

export const createVehicleFailure = (error) => ({
    type: vehicleConstants.CREATING_VEHICLE_FAILURE,
    ...error,
});

export const createVehicle = (vehicle) => {
    return (dispatch) => {
        dispatch(startCreateVehicle());

        const promise = vehicleApi.create(vehicle);

        promise
            .then((result) => {
                dispatch(createVehicleSuccess(result, vehicle));
            })
            .catch((error) => {
                dispatch(createVehicleFailure(error));
            });

        return promise;
    };
};

/**
 * Updating vehicle
 */
export const changeUpdateVehicle = (name, value) => ({
    type: vehicleConstants.UPDATING_VEHICLE_CHANGE_VALUE,
    name,
    value,
});
export const startUpdateVehicle = () => ({
    type: vehicleConstants.UPDATING_VEHICLE_STARTED,
});

export const updateVehicleSuccess = (result, vehicle) => ({
    type: vehicleConstants.UPDATING_VEHICLE_SUCCESS,
    vehicle,
    ...result,
});

export const updateVehicleFailure = (error) => ({
    type: vehicleConstants.UPDATING_VEHICLE_FAILURE,
    ...error,
});

export const updateVehicle = (vehicle) => {
    return (dispatch) => {
        dispatch(startUpdateVehicle());

        const promise = vehicleApi.update(vehicle);

        promise
            .then((result) => {
                dispatch(updateVehicleSuccess(result, vehicle));
            })
            .catch((error) => {
                dispatch(updateVehicleFailure(error));
            });

        return promise;
    };
};

/**
 * Deleting vehicle
 */
export const startDeleteVehicle = () => ({
    type: vehicleConstants.DELETE_VEHICLE_STARTED,
});

export const deleteVehicleSuccess = (result, vehicle) => ({
    type: vehicleConstants.DELETE_VEHICLE_SUCCESS,
    vehicle,
    ...result,
});

export const deleteVehicleFailure = (error) => ({
    type: vehicleConstants.DELETE_VEHICLE_FAILURE,
    ...error,
});
export const deleteVehicle = (vehicle) => {
    return (dispatch) => {
        dispatch(startDeleteVehicle());

        const promise = vehicleApi.remove(vehicle);

        promise
            .then((result) => {
                dispatch(deleteVehicleSuccess(result, vehicle));
            })
            .catch((error) => {
                dispatch(deleteVehicleFailure(error));
            });

        return promise;
    };
};

/**
 * Fetching vehicles
 */
export const startGetVehicles = (from) => ({
    type: vehicleConstants.GET_VEHICLES_STARTED,
    from: from,
});

export const getVehiclesSuccess = (result, from) => ({
    type: vehicleConstants.GET_VEHICLES_SUCCESS,
    from: from,
    vehicles: result
});

export const getVehiclesFailure = (error) => ({
    type: vehicleConstants.GET_VEHICLES_FAILURE,
    ...error,
});

export const getVehicles = (from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetVehicles(from));

        const promise = vehicleApi.latest(from, amount);

        promise
            .then((result) => {
                dispatch(getVehiclesSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getVehiclesFailure(error));
            });

        return promise;
    };
};

export const searchVehicles = (searchParameters = [], from = 0, amount = 20) => {
    return (dispatch) => {
        dispatch(startGetVehicles(from));

        const promise = vehicleApi.search(searchParameters, from, amount);

        promise
            .then((result) => {
                dispatch(getVehiclesSuccess(result, from));
            })
            .catch((error) => {
                dispatch(getVehiclesFailure(error));
            });

        return promise;
    };
};
    
