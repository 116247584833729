
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import Loader from '@uicomponents/Loader';
import UpdateBusinessContainer from '@containers/business/crud/UpdateBusinessContainer';

class BusinessView extends Component {
    render() {
        const { t, business, isFetchingBusiness, addToStack } = this.props;

        if (isFetchingBusiness || !business) return <Loader />;

        const latLonPointGeoReference = business.geoReference;

        return (
            <div className="business-view" key={`business-${business.id}`}>
                <div className="flex-container justify-between">
                    <div>
                        <div className="flex-container justify-between">
                            <h1 className="no-margin-bottom with-flair">
                                {business.name}
                            </h1>
                        </div>
                        <h3 className="no-margin-top">#{business.id}</h3>
                    </div>
                    <div className="self-center flex-container action-icons">
                        <div
                            className="action-icon"
                            onClick={(e) => {
                                e.preventDefault();
                                addToStack({
                                    name: t('business.update.header'),
                                    component: <UpdateBusinessContainer business={{ ...business }} />,
                                });
                            }}
                        >
                            <ReactSVG src="/icons/pencil.svg" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation('translation')(BusinessView);
    