
import React, { Component } from "react";

import Loader from "@uicomponents/Loader";
import AsideView from "@uicomponents/AsideView";

import Business from "@models/business/Business";
import BusinessForm from "./BusinessForm";

export default class CreateBusinessPage extends Component {
    
    componentDidMount() {
        const { onChange, targetName } = this.props;
        const newBusiness = new Business("latLonPointGeoReference");
        newBusiness.name = targetName || "";
        onChange("formBusiness", newBusiness);
    }

    render() {
        const { t, formBusiness, isFetchingBusiness, createBusiness } = this.props;

        if (!formBusiness || isFetchingBusiness) {
            return (
                <div className="absolute-center">
                    <Loader />
                </div>
            );
        }

        return (
            <AsideView>
                <h1>{t("business.new")}</h1>
                <BusinessForm
                    {...this.props}
                    key={formBusiness.id}
                    onSubmit={(business) => createBusiness(business)}
                />
            </AsideView>
        );
    }
}
    