
import api from "../api";


// CRUD
export const get = (id = '') => {
    return api.get(`/brand/${id}`);
};

export const create = (brand) => {
    return api.post(`/brands/brand`, brand);
};

export const update = (brand) => {
    return api.put(`/brands/brand`, brand);
};

export const remove = (brand) => {
    return api.delete(`/brand/${brand.id}`);
};


// Fetch and Search
export const latest = (offset = 0, amount = 20) => {
    return api.get(`/brands/all-brands`);
};

export const search = (searchParameters = [], offset = 0, amount = 20) => {
    return api.post(`/brands/all-brands`, searchParameters);
};
