
export const maintenanceConstants = {
    MAINTENANCE_CHANGE_VALUE: "MAINTENANCE_CHANGE_VALUE",

    GET_MAINTENANCE_STARTED: "GET_MAINTENANCE_STARTED",
    GET_MAINTENANCE_SUCCESS: "GET_MAINTENANCE_SUCCESS",
    GET_MAINTENANCE_FAILURE: "GET_MAINTENANCE_FAILURE",

    GET_MAINTENANCES_STARTED: "GET_MAINTENANCES_STARTED",
    GET_MAINTENANCES_SUCCESS: "GET_MAINTENANCES_SUCCESS",
    GET_MAINTENANCES_FAILURE: "GET_MAINTENANCES_FAILURE",

    SEARCH_MAINTENANCES_STARTED: "SEARCH_MAINTENANCES_STARTED",
    SEARCH_MAINTENANCES_SUCCESS: "SEARCH_MAINTENANCES_SUCCESS",
    SEARCH_MAINTENANCES_FAILURE: "SEARCH_MAINTENANCES_FAILURE",

    CREATING_MAINTENANCE_STARTED: "CREATING_MAINTENANCE_STARTED",
    CREATING_MAINTENANCE_SUCCESS: "CREATING_MAINTENANCE_SUCCESS",
    CREATING_MAINTENANCE_FAILURE: "CREATING_MAINTENANCE_FAILURE",

    UPDATING_MAINTENANCE_STARTED: "UPDATING_MAINTENANCE_STARTED",
    UPDATING_MAINTENANCE_SUCCESS: "UPDATING_MAINTENANCE_SUCCESS",
    UPDATING_MAINTENANCE_FAILURE: "UPDATING_MAINTENANCE_FAILURE",

    DELETE_MAINTENANCE_STARTED: "DELETE_MAINTENANCE_STARTED",
    DELETE_MAINTENANCE_SUCCESS: "DELETE_MAINTENANCE_SUCCESS",
    DELETE_MAINTENANCE_FAILURE: "DELETE_MAINTENANCE_FAILURE",
};
