
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import Loader from '@uicomponents/Loader';
import UpdateBrandContainer from '@containers/brand/crud/UpdateBrandContainer';

class BrandView extends Component {
    render() {
        const { t, brand, isFetchingBrand, addToStack } = this.props;

        if (isFetchingBrand || !brand) return <Loader />;

        const latLonPointGeoReference = brand.geoReference;

        return (
            <div className="brand-view" key={`brand-${brand.id}`}>
                <div className="flex-container justify-between">
                    <div>
                        <div className="flex-container justify-between">
                            <h1 className="no-margin-bottom with-flair">
                                {brand.name}
                            </h1>
                        </div>
                        <h3 className="no-margin-top">#{brand.id}</h3>
                    </div>
                    <div className="self-center flex-container action-icons">
                        <div
                            className="action-icon"
                            onClick={(e) => {
                                e.preventDefault();
                                addToStack({
                                    name: t('brand.update.header'),
                                    component: <UpdateBrandContainer brand={{ ...brand }} />,
                                });
                            }}
                        >
                            <ReactSVG src="/icons/pencil.svg" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation('translation')(BrandView);
    